
import { ActionTypes } from "@/store/action-types";
import { ReportUserDetailsRequest, ReportUserDetailsResult } from "@/types/SharedTypes";
import { StaticPhrases } from "@/types/StaticPhrases";
import StringHelper from "@/types/StringHelper";
import axios from "axios";
import { nanoid } from "nanoid";
import { defineComponent } from "vue";




export default defineComponent({
    name: "ReportUserDetails",
    components: {
    },
    data() {
        return {
            userId: null as null | string,
            email: null as null | string,
            reportUserDetailsResult: null as null | ReportUserDetailsResult
        };
    },
    mounted() {
        this.reportUserDetails();
    },
    methods: {
        reportUserDetails() {
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                userId: this.userId,
                userEMail: this.email
            } as ReportUserDetailsRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/reportUserDetails", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as ReportUserDetailsResult;
                        this.reportUserDetailsResult = result;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        formatDateLong(inputDate: string | null): string {
            if (inputDate) return StringHelper.formatDateLong(inputDate);
            return "";
        },
    }

});

