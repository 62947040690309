interface MyState {
    idToken: string | undefined;
    loginSessionId: string | undefined;
    apiUrl: string;
}

export const state: MyState = {
    idToken: undefined,
    loginSessionId: undefined,
    apiUrl: "https://api.onaylarim.com/",
};

export type State = typeof state;
