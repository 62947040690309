export default {
  formatCurrency(inputNumber: number | undefined, currency: string | undefined): string {
    if (!currency) {
      return "";
    }
    if (!inputNumber) {
      return "";
    }
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(inputNumber); /* $2,500.00 */
  },
  formatDecimal(amount: number, fractionDigits = 2): string {
    const formatter = new Intl.NumberFormat("tr-TR", {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    });

    return formatter.format(amount); /* $2,500.00 */
  },
  formatDateLong(inputDate: string): string {
    const options = { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" } as Intl.DateTimeFormatOptions;
    const today = new Date(inputDate);
    return today.toLocaleDateString("en-US", options);
  },
};
