import { GetterTree } from "vuex";
import { State } from "./state";

export type Getters = {
    getIdToken(state: State): string | undefined;
    getLoginSessionId(state: State): string | undefined;
    getApiUrl(state: State): string;
};

export const getters: GetterTree<State, State> & Getters = {
    getIdToken: (state) => {
        return state.idToken;
    },
    getLoginSessionId: (state) => {
        return state.loginSessionId;
    },
    getApiUrl: (state) => {
        return state.apiUrl;
    },
};
