
import { defineComponent } from "vue";
import axios from "axios";
import { nanoid } from "nanoid";
import { StaticPhrases } from "@/types/StaticPhrases";
import { GetReceiptToGenerateRequest, GetReceiptToGenerateResultItem } from "@/types/SharedTypes";
import { ActionTypes } from "@/store/action-types";

export default defineComponent({
    name: "ReceiptsView",
    data() {
        return {
            selectedReceipt: undefined as undefined | GetReceiptToGenerateResultItem,
            columns: [] as Array<string>,
            items: [] as Array<GetReceiptToGenerateResultItem>,
            dontShowColumns: [
                "receipts_ReceiptAddressId",
                "receipts_ReceiptDate",
                "userCreditTransactions_FlowId",
                "userCreditTransactions_CreatedDate",
                "userCreditTransactions_TransactionType",
                "moneyTransfers_CreatedDate",
                "iyzicoPaymentResults_CreatedDate",
                "iyzicoPaymentResults_CardToken",
                "iyzicoPaymentResults_BinNumber",
                "iyzicoPaymentResults_LastFourDigits",
                "iyzicoPaymentResults_PaymentItems",
            ],
        };
    },
    mounted() {
        this.getReceiptToGenerate();
    },
    methods: {
        getReceiptToGenerate() {
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
            } as GetReceiptToGenerateRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/getReceiptToGenerate", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        if (e.data.result !== undefined && e.data.result !== null && e.data.result.items !== undefined && e.data.result.items !== null && e.data.result.items.length > 0) {
                            this.items = e.data.result.items;
                            this.columns = Object.keys(this.items[0]).map((key) => {
                                return key;
                            });
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTWARNING, "Fatura bulunamadı.");
                        }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        addFile(receipt: GetReceiptToGenerateResultItem) {
            if (receipt === undefined || receipt === null) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Seçili fatura kaydı yok.");
                return;
            }
            if (receipt.receiptNumber === undefined || receipt.receiptNumber === null || receipt.receiptNumber.length === 0) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Fatura no girilmeli.");
                return;
            }
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement !== undefined && fileElement !== null && fileElement.files !== undefined && fileElement.files !== null) {
                this.selectedReceipt = receipt;
                fileElement.click();
            }
        },
        handleFileUpload() {
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement !== undefined && fileElement !== null && fileElement.files !== undefined && fileElement.files !== null && fileElement.files.length > 0) {
                for (let i = 0; i < fileElement.files.length; i++) {
                    this.uploadFile(fileElement.files[i]);
                }
            }
        },
        uploadFile(file: File) {
            const formData = new FormData();
            let receiptId = 0;
            let loginSessionId = "";
            let receiptNumber = "";
            let idToken = "";
            if (this.selectedReceipt !== undefined) {
                receiptId = this.selectedReceipt.receiptId;
                receiptNumber = this.selectedReceipt.receiptNumber;
            } else {
                //
                return;
            }

            if (this.$store.getters.getLoginSessionId !== undefined) {
                loginSessionId = this.$store.getters.getLoginSessionId;
            } else {
                //
                return;
            }
            if (this.$store.getters.getIdToken !== undefined) {
                idToken = this.$store.getters.getIdToken;
            } else {
                //
                return;
            }

            const headers = { "Content-Type": "multipart/form-data", receiptid: receiptId, receiptnumber: receiptNumber, requestid: nanoid(), loginsessionid: loginSessionId, Authorization: "Bearer " + idToken };

            formData.append("file", file);

            axios
                .post(this.$store.getters.getApiUrl + "admin/uploadReceipt", formData, {
                    headers: headers,
                    onUploadProgress: () => {
                        // file.uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    },
                })
                .then((result) => {
                    if (result.data !== undefined && result.data !== null) {
                        const apiResult = result.data as any;
                        if (apiResult !== undefined && apiResult !== null) {
                            if (apiResult.error !== undefined && apiResult.error !== null) {
                                this.$store.dispatch(ActionTypes.TOASTERROR, apiResult.error);
                            } else {
                                this.$store.dispatch(ActionTypes.TOASTSUCCESS, "Fatura yüklendi.");
                            }
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yükleme sonucu geçersiz.");
                        }
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, "Yükleme sonucu geçersiz bir değer.");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
    },
});
