

export interface FinishJobSendSftpParameters {
    selectedSftpTargetId: number | null;
}

export interface FinishJobSendKepParameters {
    senderKepAccountId: number | null;
    kepBody: string;
    targets: FinishJobSendKepParametersTarget[];
}

export interface FinishJobSendKepParametersTarget {
    contactId: number | null;
    kepAddress: string;
}



export interface AddCreditPayTrStepOneRequest extends BaseRequest {
    loginSessionId: string;
    receiptAddressId: number;
    cardHolderName: string;
    cardNumber: string;
    expireMonth: string;
    expireYear: string;
    cvv: string;
    purchasedCreditPackageName: string;
}

export interface AddCreditPayTrStepOneResult {
    threeDPageRelativeUrl: string;
    oid: string;
    noRight: boolean;
}

export interface AddFlowItemRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    groupNo: number | null;
}

export interface AddFlowNoteRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    noteText: string;
    isPublic: boolean;
}

export interface AddFlowNoteWithTokenRequest extends BaseRequest {
    token: string;
    flowId: number;
    noteText: string;
}

export interface CreateJwtShortCodeRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    tokenStart: string;
}

export interface AddFlowItemResult {
    color: string;
    id: number;
    orderNo: number;
    groupNo: number;
    flowItemType: FlowItemTypeEnum;
    groupAuthorityType: FlowItemGroupAuthorityTypeEnum;
    flowItems: AddFlowItemResultFlowItem[];
}

export interface AddFlowNoteResult {
    id: number;
    flowId: number;
    attachmentFileName: string;
    createdDate: string;
    creatorFlowItem_Email: string;
    creatorUser_DisplayName: string;
    creatorUserId: number | null;
    creatorUser_EMail: string;
    isPublic: boolean;
    noteText: string;
}

export interface CreateJwtShortCodeResult {
    jwtShortCode: string;
}

export interface AddFlowItemResultFlowItem {
    id: number;
    eMail: string;
    fullname: string;
    orderNo: number;
    color: string;
    groupNo: number;
    flowItemType: FlowItemTypeEnum;
    groupAuthorityType: FlowItemGroupAuthorityTypeEnum;
}

export interface GetEYazismaCreatorRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetEYazismaCreatorResult {
    contact: SaveEYazismaCreatorRequestContact;
    company: SaveEYazismaCreatorRequestCompany;
    institution: SaveEYazismaCreatorRequestInstitution;
    person: SaveEYazismaCreatorRequestPerson;
}

export interface SaveEYazismaCreatorRequest extends BaseRequest {
    loginSessionId: string;
    contact: SaveEYazismaCreatorRequestContact;
    company: SaveEYazismaCreatorRequestCompany;
    institution: SaveEYazismaCreatorRequestInstitution;
    person: SaveEYazismaCreatorRequestPerson;
}

export interface SaveEYazismaCreatorRequestPerson {
    id: number | null;
    citizenshipNo: string;
    duty: string;
    firstName: string;
    lastName: string;
    secondName: string;
    title: string;
    prefix: string;
}

export interface SaveEYazismaCreatorRequestInstitution {
    id: number | null;
    kkk: string;
    name: string;
    departmentKkk: string;
}

export interface SaveEYazismaCreatorRequestCompany {
    id: number | null;
    mersisNo: string;
    name: string;
}

export interface SaveEYazismaCreatorRequestContact {
    id: number | null;
    phoneNumber: string;
    phoneNumberOther: string;
    eMail: string;
    kepMailAddress: string;
    fax: string;
    webSite: string;
    address: string;
    city: string;
    county: string;
    country: string;
}

export interface AddMoneyTransferRequest extends BaseRequest {
    loginSessionId: string;
    receiptAddressId: number;
    purchasedCreditPackageName: string;
}

export interface GetDeployItemRequest extends BaseRequest {
    appName: string;
    version: string;
    name: string;
    hash: string;
    licenseString: string;
}

export interface GetDeployVersionsRequest extends BaseRequest {
    appName: string;
    licenseString: string;
}

export interface GetPackageFileRequest extends BaseRequest {
    appName: string;
    version: string;
    licenseString: string;
}

export interface AddMoneyTransferForLicenseRequest extends BaseRequest {
    loginSessionId: string;
    receiptAddressId: number;
    newLicenseCount: number;
    organizationNewDisplayName: string;
    organizationNewLegalName: string;
}

export interface AddMoneyTransferForUpgradePlanRequest extends BaseRequest {
    loginSessionId: string;
    receiptAddressId: number;
    planName: string;
    organizationNewDisplayName: string;
    organizationNewLegalName: string;
}

export interface AddMoneyTransferResult {
    addedMoneyTransferesId: number;
    transferCode: string;
    bankAccounts: AddMoneyTransferResultBankAccount[];
    companyLegalName: string;
    noRight: boolean;
}

export interface SaveEYazismaCreatorResult {
    noRight: boolean;
}

export interface AddMoneyTransferResultBankAccount {
    name: string;
    iban: string;
}

export interface AddMoneyTransferForLicenseResult {
    addedMoneyTransferesId: number;
    transferCode: string;
    bankAccounts: AddMoneyTransferForLicenseResultBankAccount[];
    companyLegalName: string;
    noRight: boolean;
}

export interface AddMoneyTransferForLicenseResultBankAccount {
    name: string;
    iban: string;
}

export interface AddMoneyTransferForUpgradePlanResult {
    addedMoneyTransferesId: number;
    transferCode: string;
    bankAccounts: AddMoneyTransferForLicenseResultBankAccount[];
    companyLegalName: string;
    noRight: boolean;
}

export interface AddReceiptAddressRequest extends BaseRequest {
    loginSessionId: string;
    address: string;
    cityName: string;
    companyName: string;
    companyTaxNo: string;
    companyTaxOffice: string;
    country: string;
    personFirstName: string;
    personLastName: string;
    citizenshipNo: string;
    displayName: string;
    isCompany: boolean;
    zipCode: string;
}

export interface AddReceiptAddressResult {
    addedReceiptAddressesId: number;
    noRight: boolean;
}

export enum ApiUserTypeEnum {
    SignHub = 0,
    CoreApi = 1
}





export interface ApproveMoneyTransferRequest extends BaseRequest {
    loginSessionId: string;
    moneyTransferId: number;
}

export interface ApproveMoneyTransferResult {
    dummy: number;
}

export interface BaseRequest {
    requestId: string;
    displayLanguage: string;
}



export interface CancelFlowRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface DublicateFlowRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}



export interface CancelFlowResult {
    warnings: string[];
    newStatus: FlowStatusEnum;
    newStatusString: string;
    flowId: number;
    lastUpdatedDateString: string;
}

export interface CertificateInfo {
    serialNumber: string;
    id: string;
    personFullname: string;
    validFrom: string;
    validTo: string;
    citizenshipNo: string;
    data: string;
    pkcsLibrary: string;
    slot: number;
    isFinancialSeal: boolean;
}

export interface SignerAppPingResult {
    signerAppStatus: number;
    signerAppStatusString: string;
    signerAppDllVersion: number;
    error: string;
    signerAppPlatform: string;
}

export interface SignerAppResetResult {
    error: string;
    certificates: CertificateInfo[];
    signerAppStatus: number;
    signerAppStatusString: string;
    signerAppDllVersion: number;
    signerAppPlatform: string;
}

export interface SignStepTwoResult {
    error: string;
    signedData: string;
}

export interface SignerAppRestartResult {
    error: string;
    result: boolean;
}

export interface SignerAppCertificatesResult {
    certificates: CertificateInfo[];
    error: string;
}

export interface SignerAppGetCertificateResult {
    error: string;
}

export interface SignerAppSignResult {
    error: string;
}

export interface ChangeMyDisplayNameRequest extends BaseRequest {
    loginSessionId: string;
    newDisplayName: string;
}

export interface ChangeMyPhoneNumberRequest extends BaseRequest {
    loginSessionId: string;
    newPhoneNumber: string;
    newGsmOperator: string;
}

export interface SendEMailVerifyEMailRequest extends BaseRequest {
    loginSessionId: string;
}

export interface CheckEMailVerifiedRequest extends BaseRequest {
    loginSessionId: string;
}

export interface VerifyEMailRequest extends BaseRequest {
    oobCode: string;
}

export interface CreateNoltTokenRequest extends BaseRequest {
    loginSessionId: string;
}

export interface ChangeMyDisplayNameResult {
    newDisplayName: string;
}

export interface ChangeMyPhoneNumberResult {
    newPhoneNumber: string;
    newGsmOperator: string;
}

export interface SendEMailVerifyEMailResult {
    verifyEMailSentSuccessfully: boolean | null;
}

export interface CheckEMailVerifiedResult {
    eMailVerified: boolean | null;
}

export interface VerifyEMailResult {
    eMailVerified: boolean;
}

export interface CreateNoltTokenResult {
    dummy: number;
    token: string;
}

export interface CheckEMailRequest extends BaseRequest {
    eMail: string | null;
}

export interface CheckEMailResult {
    itsNewUser: boolean;
    itsOldUser: boolean;
}

export interface CheckOtpRequest extends BaseRequest {
    signerToken: string;
    otp: string;
}

export interface CheckJwtShortCodeRequest extends BaseRequest {
    signerToken: string;
    jwtShortCode: string;
}

export interface GetSignPortalDataRequest extends BaseRequest {
    flowId: number;
    loginSessionId: string;
}

export interface EnableUimRequest extends BaseRequest {
    loginSessionId: string;
    computerName: string;
    deviceId: string;
    citizenshipNo: string;
    certificateData: string;
    phoneNumber: string;
    pkcsLibrary: string;
    slot: number;
    apiAddress: string;
}

export interface DisableUimRequest extends BaseRequest {
    loginSessionId: string;
    uimSettingId: number;
    uniqueId: string;
}

export interface GetUimSettingRequest extends BaseRequest {
    loginSessionId: string;
}

export interface TestUimSettingRequest extends BaseRequest {
    loginSessionId: string;
    uimSettingId: number;
    uniqueId: string;
}

export interface CheckJwtShortCodeResult {
    incorrectToken: boolean;
    incorrectTokenDetail: string;
    incorrectJwtShortCode: boolean;
    incorrectJwtShortCodeDetail: string;
    jwt: string;
}

export interface CheckOtpResult {
    incorrectOtp: boolean;
    incorrectToken: boolean;
    signPortalData: SignPortalData;
    incorrectTokenDetail: string;
    sendFlowEMailPostmarkError: PostmarkError;
}

export interface GetUimSettingResult {
    id: number;
    createdDate: string;
    computerName: string;
    deviceId: string;
    certificateCitizenshipNo: string;
    certificateSerialNumber: string;
    noUim: boolean;
    phoneNumber: string;
    uniqueId: string;
}

export interface TestUimSettingResult {
    isSuccess: boolean;
    error: string;
}

export interface EnableUimResult {
    isOk: boolean;
    uniqueIdAndApiAddress: string;
}

export interface DisableUimResult {
    isOk: boolean;
}

export interface UimSettingItem {
    isOk: boolean;
}

export interface GetSignPortalDataResult {
    incorrectOtp: boolean;
    incorrectToken: boolean;
    signPortalData: SignPortalData;
}

export interface CheckOtpResultFlowDocument {
    id: number;
    documentName: string;
    pageCount: number;
    pages: CheckOtpResultFlowPage[];
}

export interface CheckOtpResultFlowPage {
    id: number;
    width: number;
    height: number;
    pageNo: number;
    documentName: string;
    totalPageCount: number;
    signatures: CheckOtpResultFlowPageSignature[];
    freeTextBoxes: CheckOtpResultFlowPageFreeTextBox[];
}

export interface CheckOtpResultFlowItem {
    id: number;
    eMail: string;
    fullname: string;
    orderNo: number;
    color: string;
    status: FlowItemStatusEnum;
    statusString: string;
    signedDateString: string;
    isSelf: boolean;
    sendDateString: string;
    groupNo: number;
    groupAuthorityType: FlowItemGroupAuthorityTypeEnum;
    flowItemType: FlowItemTypeEnum;
    groupAuthorityTypeString: string;
    isMobileSign: boolean | null;
    flowItemTypeString: string;
}

export interface CheckOtpResultFlowPageSignature {
    id: number;
    width: number;
    height: number;
    x: number;
    y: number;
    pageId: number;
    documentFolder: string;
    flowItem: CheckOtpResultFlowItem;
}

export interface CheckOtpResultFlowPageFreeTextBox {
    id: number;
    width: number;
    height: number;
    x: number;
    y: number;
    pageId: number;
    content: string;
}

export interface CheckPaymentPayTrRequest extends BaseRequest {
    loginSessionId: string;
    oid: string;
}

export interface CheckPaymentPayTrResult {
    payTrStatus: string;
    payTrFailedReasonMsg: string;
    creditBalanceString: string;
    creditBalance: number;
}

export enum ConfirmationModeEnum {
    VerifyOnly = 1,
    VerifyAndDownload = 2,
    DontUseConfirmationArea = 3
}

export enum SubscriptionUpgradeStatusEnum {
    Created = 1,
    CreatedAndApproved = 2,
    Approved = 3,
    Declined = 4
}

export enum SubscriptionUpgradeUpgradeTypeEnum {
    PlanUpgradedByUser = 1,
    CanSendSFTP = 2,
    CanSendKEP = 3,
    CanUseAdvancedFlow = 4,
    CanCreateEyp1 = 5,
    CanSendToExternalUsers = 6,
    TotalLicenseCount = 7,
    UpgradeToNewPlan = 8,
    TotalApiLicenseCount = 9,
    TotalCoreApiLicenseCount = 10,
    CanUseUim = 11
}

export interface ConfirmPasswordResetRequest extends BaseRequest {
    oobCode: string;
    newPassword1: string;
    newPassword2: string;
}

export interface ConfirmPasswordResetResult {
    passwordChanged: boolean;
}

export enum ContactTypeEnum {
    Person = 1,
    Company = 2,
    Institution = 3
}



export interface CreateDraftFlowRequest extends BaseRequest {
    loginSessionId: string;
    departmentId: number | null;
    signatureType: SignatureTypeEnum;
    qrCodeMode: QrCodeModeEnum | null;
    confirmationMode: ConfirmationModeEnum | null;
}

export interface CreateFlowRequest extends BaseRequest {
    loginSessionId: string;
    departmentId: number | null;
    subject: string;
    signatureType: SignatureTypeEnum;
    flowItems: CreateFlowRequestFlowItem[];
    qrCodeMode: QrCodeModeEnum | null;
    confirmationMode: ConfirmationModeEnum | null;
}

export interface CreateFlowRequestFlowItem {
    fullname: string;
    eMail: string;
    citizenshipNo: string;
    orderNo: number;
    groupNo: number;
    flowItemType: FlowItemTypeEnum;
    flowItemGroupAuthorityType: FlowItemGroupAuthorityTypeEnum;
    byPassOtpForExternalUser: boolean | null;
}

export interface CreateDraftFlowResult {
    flowId: number;
    flowItems: CreateDraftFlowResultFlowItem[];
}

export interface CreateDraftFlowResultFlowItem {
    orderNo: number;
    eMail: string;
    flowItemId: number;
}



export interface CreateFlowResult {
    flowId: number;
}

export interface SaveFlowResult {
    flowId: number;
}

export interface SaveTcForFlowItemResult {
    newCitizenshipNo: string;
    flowItemId: number;
}

export interface CreatePaymentRequest extends BaseRequest {
    loginSessionId: string;
    receiptAddressId: number;
}

export interface CreatePaymentResult {
    paymentPageUrl: string;
    checkoutFormContent: string;
    purchaseId: number;
    discountAmount: number;
    totalPrice: number;
    totalPriceAfterDiscount: number;
}





export interface DeleteAccountRequest extends BaseRequest {
    password: string;
    loginSessionId: string;
}

export interface DeleteUserRequest extends BaseRequest {
    loginSessionId: string;
    userIdToDelete: number;
}

export interface DeleteAccountResult {
    deletedItemCount: number;
    accountDeleted: boolean;
}

export interface DeleteUserResult {
    userDeletedFromFirebase: boolean;
    userDeletedFromDatabase: boolean;
}

export interface DeleteDocumentRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    flowDocumentId: number | null;
    flowDocumentEYazismaAttachmentId: number | null;
}

export interface DeleteDocumentResult {
    changes: DeleteDocumentResultChange[];
}

export interface DeleteDocumentResultChange {
    id: number;
    orderNo: number;
    color: string;
}

export interface ChangeFlowItemFlowItemTypeRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    flowItemId: number;
    flowItemType: FlowItemTypeEnum;
}

export interface ChangeFlowItemGroupAuthorityTypeRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    flowItemId: number;
    groupAuthorityType: FlowItemGroupAuthorityTypeEnum;
}

export interface SwitchModeRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    newMode: FlowTypeEnum;
}

export interface DeleteFlowItemRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    flowItemId: number;
    forceDelete: boolean;
}

export interface DeleteFlowNoteRequest extends BaseRequest {
    loginSessionId: string;
    flowNoteId: number;
}

export interface DeleteFlowNoteWithTokenRequest extends BaseRequest {
    token: string;
    flowNoteId: number;
}

export interface ChangeOrderFlowRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    flowItemId: number;
    orderIncrement: number;
}

export interface SwitchModeResult {
    newMode: FlowTypeEnum;
    showAds: boolean;
}

export interface DeleteFlowItemResult {
    flowItemChanges: DeleteFlowItemResultFlowItemChange[];
    thereAreSignaturesOfThisFlowItem: boolean;
}

export interface DeleteFlowNoteResult {
    isDeleted: boolean;
    flowNoteId: number;
}

export interface ChangeFlowItemGroupAuthorityTypeResult {
    newGroupAuthorityType: FlowItemGroupAuthorityTypeEnum;
}

export interface ChangeFlowItemFlowItemTypeResult {
    newFlowItemType: FlowItemTypeEnum;
    newGroupAuthorityType: FlowItemGroupAuthorityTypeEnum;
}

export interface DeleteFlowItemResultFlowItemChange {
    id: number;
    orderNo: number;
    color: string;
}

export interface ChangeOrderFlowItemResult {
    flowItemChanges: ChangeOrderFlowItemResultFlowItemChange[];
}

export interface ChangeOrderFlowItemResultFlowItemChange {
    id: number;
    orderNo: number;
    color: string;
    groupNo: number;
}

export interface DeleteFlowRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface DeleteFilesOfFlowRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface DeleteFlowResult {
    deletedItemCount: number;
    flowStatus: FlowStatusEnum;
}

export interface DeleteFilesOfFlowResult {
    deletedItemCount: number;
    flowStatus: FlowStatusEnum;
}

export interface DeleteReceiptAddressRequest extends BaseRequest {
    loginSessionId: string;
    receiptAddressId: number;
}

export interface DeleteSftpTargetRequest extends BaseRequest {
    loginSessionId: string;
    sftpTargetId: number;
}

export interface SendToSftpTargetRequest extends BaseRequest {
    loginSessionId: string;
    sftpTargetId: number;
    flowId: number;
    userNote: string;
}

export interface TestSftpTargetRequest extends BaseRequest {
    loginSessionId: string;
    sftpTargetId: number;
}

export interface DeleteKepAccountRequest extends BaseRequest {
    loginSessionId: string;
    kepAccountId: number;
}

export interface DeleteApiUserRequest extends BaseRequest {
    loginSessionId: string;
    apiUserId: number;
}

export interface DeleteReceiptAddressResult {
    noRight: boolean;
}

export interface DeleteSftpTargetResult {
    noRight: boolean;
}

export interface SendToSftpTargetResult {
    noRight: boolean;
    sendSuccessfully: boolean;
}

export interface TestSftpTargetResult {
    noRight: boolean;
    testSuccesfull: boolean;
}

export interface DeleteKepAccountResult {
    noRight: boolean;
}

export interface DeleteApiUserResult {
    noRight: boolean;
}

export enum DepartmentUserRightEnum {
    CreateAndViewFlows = 2,
    ViewFlows = 3,
    SendKepMessage = 4,
    ViewContacts = 5,
    ManageContacts = 6
}

export interface DownloadReceiptPdfRequest extends BaseRequest {
    loginSessionId: string;
    receiptId: number;
}

export interface DownloadReceiptPdfResult {
    receiptPdfData: string;
}

export interface DublicateFlowResult {
    dublicated: boolean;
    tempFilesDeleted: boolean;
    files: string[];
    newFlowId: number | null;
}



export enum FileTypeEnum {
    OriginalFile,
    AllOriginalFile,
    UnSignedPdf,
    SignedPades,
    SignedCades
}

export interface FinishUploadRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface FinishUploadResult {
    myProperty: number;
}

export enum FlowAccessTokenTypeEnum {
    ForViewing = 1,
    ForEditing = 2,
    ForDownload = 3,
    ForViewToSign = 4
}

export enum WebHookEventTypeEnum {
    FlowCreated = 1,
    FlowUpdated = 2,
    FlowDigitalSigned = 4,
    FlowMobileSigned = 8,
    FlowApproved = 16,
    FlowViewed = 32,
    FlowDeclined = 64,
    FlowStarted = 128,
    FlowCompletedSuccessfully = 256,
    FlowCancelled = 512
}

export enum FlowHistoryTypeEnum {
    Cancel = 1,
    Created = 2,
    SendToPerson = 3,
    OpenSigningPortal = 4,
    OpenSigningPortalCheckOtp = 5,
    ViewDocumentFromApp = 6,
    Sign = 7,
    VerifiedWithDownload = 8,
    VerifiedOnly = 9,
    EditDocumentFromApp = 10,
    DownloadDocumentFromVerify = 11,
    ViewDocumentForSign = 12,
    StartFlow = 13,
    DownloadFromApp = 14,
    DeleteFilesByUser = 15,
    DeleteFilesBySystem = 16,
    MobileSign = 17,
    SendViaSftp = 18,
    Approve = 19,
    SendViaKep = 20,
    Decline = 21,
    CreateKepMail = 22,
    UimSign = 23
}

export enum FlowItemGroupAuthorityTypeEnum {
    None = 0,
    Authority = 1,
    SharedAuthority = 2
}

export enum FlowItemStatusEnum {
    Default = 1,
    Waiting = 10,
    OpenedPortal = 20,
    ReadDocument = 30,
    Finished = 40,
    Declined = 50,
    Passed = 60
}

export enum FlowItemTypeEnum {
    eSignature = 0,
    Approve = 1,
    Viewer = 2
}

export enum CoreApiOperationTypeEnum {
    CadesSign = 1,
    PadesSign = 2,
    PadesMobileSign = 3,
    CadesMobileSign = 4,
    ConvertToPdf = 5,
    PadesSignWithLayers = 6,
    AddLayers = 7,
    UploadFile = 8,
    VerifySignature = 9,
    RegisterUser = 10
}

export enum TwoFactorAuthenticationTypeEnum {
    None = 0,
    Sms = 1
}

export enum FlowOperationActorEnum {
    AnyOne = 1,
    SelectedFlowItem = 2,
    SelectedFlowItemGroup = 3,
    SelectedUser = 4
}

export enum FlowOperationConditionEnum {
    OnAny = 1,
    OnDigitalSign = 2,
    OnMobileSign = 4,
    OnApprove = 8,
    OnView = 16,
    OnDecline = 32,
    OnStart = 64,
    OnCompletedSuccessfully = 128
}

export enum FlowStatusEnum {
    Created = 1,
    Draft = 2,
    Running = 3,
    Finished = 4,
    Cancelled = 5,
    FinishedWithDecline = 6
}

export enum FlowTypeEnum {
    Basic = 1,
    Advanced = 2
}

export enum SignatureTypeEnum {
    Pades = 0,
    Cades = 1,
    eYazisma = 2
}

export interface GenerateCaptchaRequest extends BaseRequest {
    myProperty: number;
}

export interface VerifyCadesRequest extends BaseRequest {
    myProperty: number;
    signedData: string;
}

export interface GenerateCaptchaResult {
    uniqueId: string;
    captchaImage: string;
}





export interface CheckWaitingTransferForUpgradingAccountRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetMoneyTransfersForOrganizationRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetBalancePayTrRequest extends BaseRequest {
    loginSessionId: string;
}

export interface CheckWaitingTransferForUpgradingAccountResult {
    thereIsAWaitingTransfer: boolean;
}

export interface GetMoneyTransfersForOrganizationResult {
    items: GetMoneyTransfersForOrganizationResultItem[];
    noRight: boolean;
    isFreePlan: boolean;
}

export interface GetMoneyTransfersForOrganizationResultItem {
    amount: number;
    amountKdv: number;
    createdDate: string;
    id: number;
    moneyTransferType: MoneyTransferTypeEnum;
    purchasedPackageName: string;
    receiptId: number | null;
    status: MoneyTransferStatusEnum;
    userCreditTransactionId: number | null;
    userId: number;
    userLicenseCount: number | null;
    createdDateString: string;
    amountString: string;
    amountKdvString: string;
    amountTotalString: string;
    amountTotal: number;
    receiptPdfCreationDate: string | null;
    moneyTransferTypeString: string;
    statusString: string;
    userDisplayName: string;
    userEMail: string;
}

export interface GetBalancePayTrResult {
    items: GetBalancePayTrResultItem[];
    currentCreditBalanceString: string;
    noRight: boolean;
    canBuyCreditWithOnlinePayment: boolean;
    showTransactions: boolean;
}

export interface GetBalancePayTrResultItem {
    userCreditTransaction_Id: number;
    userCreditTransaction_UserId: number;
    userCreditTransaction_FlowId: number;
    userCreditTransaction_CreditAmount: number;
    userCreditTransaction_PaymentAmount: number;
    userCreditTransaction_PaymentAmountKdv: number;
    userCreditTransaction_Description: string;
    userCreditTransaction_CreatedDate: string;
    userCreditTransaction_TransactionType: UserCreditTransactionTypeEnum | null;
    userCreditTransaction_CreditBalance: number;
    moneyTransfer_Amount: number | null;
    moneyTransfers_PurchasedPackageName: string;
    moneyTransfer_Status: MoneyTransferStatusEnum | null;
    moneyTransfer_Description: string;
    moneyTransfer_CreatedDate: string | null;
    receipt_Id: number | null;
    receipt_ReceiptNumber: string;
    receipt_ReceiptPdfCreationDate: string | null;
    receipt_CreatedDate: string | null;
    receipt_Id_2: number | null;
    receipt_ReceiptIdentifier_2: string;
    receipt_ReceiptNumber_2: string;
    receipt_ReceiptPdfCreationDate_2: string | null;
    receipt_CreatedDate_2: string | null;
    payTrPaymentResult_PayTrStatus: string;
    payTrPaymentResult_PayTrFailedReasonCode: string;
    payTrPaymentResult_PayTrFailedReasonMsg: string;
    payTrPaymentResult_LastFourDigits: string;
    flow_UniqueId: string;
    flow_Id: number;
    flow_Subject: string;
    flow_Status: FlowStatusEnum | null;
    userCreditTransaction_PaymentAmountString: string;
    userCreditTransaction_CreditBalanceString: string;
    userCreditTransaction_CreatedDateString: string;
    userCreditTransaction_TransactionTypeString: string;
    receipt_CreatedDateString_2: string;
    receipt_ReceiptPdfCreationDateString_2: string;
    moneyTransfer_StatusString: string;
    flow_StatusString: string;
    receipt_CreatedDateString: string;
    receipt_ReceiptPdfCreationDateString: string;
    moneyTransfer_CreatedDateString: string;
    receipt_CreatedDate_Final: string | null;
    receipt_Id_Final: number | null;
    receipt_ReceiptNumber_Final: string;
    receipt_ReceiptPdfCreationDate_Final: string | null;
    userCreditTransaction_PaymentAmountTotal: number;
    userCreditTransaction_PaymentAmountKdvString: string;
    userCreditTransaction_PaymentAmountTotalString: string;
}

export interface GetCreditPackagesRequest extends BaseRequest {
    myProperty: number;
}

export interface GetCreditPackagesResult {
    creditPackages: GetCreditPackagesResultCreditPackage[];
}

export interface GetCreditPackagesResultCreditPackage {
    name: string;
    title: string;
    description: string;
    price: number;
    creditAmount: number;
    priceString: string;
    priceForOneFlowString: string;
    priceKdvString: string;
    priceKdv: number;
}

export interface GetDepartmentUsersRequest extends BaseRequest {
    loginSessionId: string;
    departmentId: number;
}

export interface GetMyDepartmentRolesRequest extends BaseRequest {
    loginSessionId: string;
    departmentId: number;
}

export interface ChangeLanguageRequest extends BaseRequest {
    loginSessionId: string;
    languageCode: string;
}

export interface GetDepartmentUserRightEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetFlowItemStatusEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetOrganizationUserRightEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetContactTypeEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetApiUserTypeEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetFlowStatusEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetFlowItemTypeEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetKepProviderTypeEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetTwoFactorAuthenticationTypeEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetFlowItemGroupAuthorityTypeEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetOutgoingKepMessageStatusEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetSftpLogTypeEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetSubscriptionUpgradeUpgradeTypeEnumsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetOrganizationRolesRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetNeedsToSignedByMeRequest extends BaseRequest {
    loginSessionId: string;
    getOnlyCount: boolean;
}

export interface GetStartedByMeRequest extends BaseRequest {
    loginSessionId: string;
    getOnlyCount: boolean;
}

export interface GetContactsRequest extends BaseRequest {
    loginSessionId: string;
    searchString: string;
    searchInUsers: boolean;
    searchInContacts: boolean;
    searchInDetsis: boolean;
    mustHaveEMailAddress: boolean;
    mustHaveKepAddress: boolean;
}

export interface GetTagsRequest extends BaseRequest {
    loginSessionId: string;
    searchString: string;
}

export interface GetContactsForManagementRequest extends BaseRequest {
    loginSessionId: string;
    pagingRequest: PagingRequest;
}

export interface PagingRequest {
    itemCountInPage: number;
    currentPage: number;
    orderByColumn: string;
    sortOrder: string;
}

export interface DivvySignInRequest extends BaseRequest {
    loginSessionId: string;
    username: string;
    password: string;
}

export interface DivvyGetFoldersRequest extends BaseRequest {
    klasorId: number | null;
    loginSessionId: string;
}

export interface DivvyDownloadFileRequest extends BaseRequest {
    loginSessionId: string;
    dosyaId: string;
    flowId: number;
    fileCustomId: string;
    fileName: string;
}

export interface DivvyGetFilesRequest extends BaseRequest {
    loginSessionId: string;
    klasorId: number;
}

export interface GetDepartmentsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetDepartmentsWithMyRolesRequest extends BaseRequest {
    loginSessionId: string;
}

export interface ControlUpgradeLicenseRequest extends BaseRequest {
    loginSessionId: string;
    newPlanName: string;
}

export interface UpgradeLicenseRequest extends BaseRequest {
    loginSessionId: string;
    newPlanName: string;
    organizationNewDisplayName: string;
    organizationNewLegalName: string;
}

export interface GetLicenseInformationRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetPlansRequest extends BaseRequest {
    loginSessionId: string;
}

export interface ChangeOrganizationDisplayNameRequest extends BaseRequest {
    loginSessionId: string;
    organizationNewDisplayName: string;
}

export interface ChangeOrganizationLegalNameRequest extends BaseRequest {
    loginSessionId: string;
    organizationNewLegalName: string;
}

export interface CalculateLicensePriceRequest extends BaseRequest {
    loginSessionId: string;
    newLicenseCount: number;
}

export interface AddUserSignatureImageRequest extends BaseRequest {
    loginSessionId: string;
    imageDataBase64: string;
}

export interface GetUserSignatureImagesRequest extends BaseRequest {
    id: number | null;
    loginSessionId: string;
}

export interface SetUserSignatureImageAsDefaultRequest extends BaseRequest {
    loginSessionId: string;
    id: number;
}

export interface SaveUserSignatureImageRequest extends BaseRequest {
    loginSessionId: string;
    id: number;
    imageDataBase64: string;
    isDeleted: boolean;
}

export interface GetMyProfileInfoRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetMyOrganizationRightsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetUsersRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetSftpTargetsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface TestScribanRequest extends BaseRequest {
    loginSessionId: string;
    templateString: string;
}

export interface GetKepAccountsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetApiUsersRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetOutgoingKepMessagesRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetKepAccountsForSendingRequest extends BaseRequest {
    loginSessionId: string;
}

export interface UpdateUsersTwoFactorAuthenticationRequest extends BaseRequest {
    loginSessionId: string;
    userId: number;
    twoFactorAuthenticationType: TwoFactorAuthenticationTypeEnum;
    twoFactorPassword: string;
    twoFactorOperationName: string;
}

export interface SaveUserRequest extends BaseRequest {
    loginSessionId: string;
    userId: number;
    displayName: string;
    isActive: boolean;
    citizenshipNo: string;
    phoneNumber: string;
}

export interface SaveSftpTargetRequest extends BaseRequest {
    loginSessionId: string;
    id: number;
    displayName: string;
    isActive: boolean;
    serverAddress: string;
    serverPort: number;
    username: string;
    fingerprint: string;
    targetFolder: string;
    createFolderFormat: string;
    password: string;
    changeFilenameFormat: string;
    useSsl: boolean;
    maxFileSizeInMbs: number | null;
}

export interface SaveKepAccountRequest extends BaseRequest {
    loginSessionId: string;
    displayName: string;
    kepAccountId: number;
    pttPassKey: string;
    pttPassword: string;
    turkKepPassword: string;
    isActive: boolean;
    isDeleted: boolean;
    tnbKepAppName: string;
    tnbKepPassword: string;
    tnbKepAppPassword: string;
}

export interface SetKepAccountAsUseForGettingEvidence extends BaseRequest {
    loginSessionId: string;
    kepAccountId: number;
}

export interface SaveOrganizationalRoleRequest extends BaseRequest {
    loginSessionId: string;
    userId: number;
    id: number;
    isActive: boolean;
    role: OrganizationUserRightEnum;
    isDeleted: boolean;
}

export interface ResetUserPasswordRequest extends BaseRequest {
    loginSessionId: string;
    userId: number;
}

export interface AddUserRequest extends BaseRequest {
    loginSessionId: string;
    fullName: string;
    eMail: string;
    citizenshipNo: string;
    phoneNumber: string;
}

export interface CheckAzureUserRequest extends BaseRequest {
    accessToken: string;
}

export interface AddSftpTargetRequest extends BaseRequest {
    loginSessionId: string;
    displayName: string;
    serverAddress: string;
    serverPort: number;
    username: string;
    password: string;
    fingerprint: string;
    targetFolder: string;
    createFolderFormat: string;
    changeFilenameFormat: string;
    useSsl: boolean;
    maxFileSizeInMbs: number | null;
}

export interface AddKepAccountRequest extends BaseRequest {
    loginSessionId: string;
    displayName: string;
    kepMailAddress: string;
    kEPProvider: KEPProviderEnum;
    pttCitizenshipNumber: string;
    pttPassKey: string;
    pttPassword: string;
    turkKepOfficerId: string;
    turkKepPassword: string;
    tnbKepAccountName: string;
    tnbKepAppName: string;
    tnbKepAppPassword: string;
    tnbKepPassword: string;
}

export interface AddApiUserRequest extends BaseRequest {
    loginSessionId: string;
    name: string;
    allowedIP: string;
    apiUserType: ApiUserTypeEnum;
}

export interface SaveApiUserRequest extends BaseRequest {
    loginSessionId: string;
    name: string;
    allowedIP: string;
    apiUserId: number;
    isActive: boolean;
}

export interface AddChildDepartmentRequest extends BaseRequest {
    loginSessionId: string;
    parentDepartmentId: number | null;
    departmentName: string;
}

export interface RenameDepartmentRequest extends BaseRequest {
    loginSessionId: string;
    departmentId: number | null;
    newDisplayName: string;
}

export interface DeleteDepartmentRequest extends BaseRequest {
    loginSessionId: string;
    departmentId: number | null;
}

export interface AddDepartmentUserRequest extends BaseRequest {
    loginSessionId: string;
    departmentId: number;
    affectsChildren: boolean;
    role: DepartmentUserRightEnum;
    userId: number;
    isActive: boolean;
}

export interface AddOrganizationalRoleRequest extends BaseRequest {
    loginSessionId: string;
    organizationUserRightEnum: OrganizationUserRightEnum;
    userId: number;
    isActive: boolean;
}

export interface AddContactRequest extends BaseRequest {
    loginSessionId: string;
    departmentId: number | null;
    userId: number | null;
    contactType: ContactTypeEnum;
    fullName: string;
    email: string;
    kepAddress: string;
    workingCompanyName: string;
    taxNo: string;
    ownerType: number;
    kkk: string;
    mersisNo: string;
    workingForEYazismaTarget: boolean;
    citizenshipNo: string;
}

export interface AddTagRequest extends BaseRequest {
    loginSessionId: string;
    name: string;
    flowId: number;
    tagId: number | null;
}

export interface RemoveTagRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    tagId: number;
}

export interface SaveContactRequest extends BaseRequest {
    loginSessionId: string;
    departmentId: number | null;
    userId: number | null;
    contactType: ContactTypeEnum;
    fullName: string;
    email: string;
    kepAddress: string;
    workingCompanyName: string;
    id: number;
    isDeleted: boolean;
    taxNo: string;
    kkk: string;
    mersisNo: string;
    citizenshipNo: string;
}

export interface SaveDepartmentUserRequest extends BaseRequest {
    loginSessionId: string;
    departmentUserId: number;
    affectsChildren: boolean;
    role: DepartmentUserRightEnum;
    userId: number;
    isDeleted: boolean;
    isActive: boolean;
    departmentId: number;
}

export interface ChangeOrganizationDisplayNameResult {
    organizationNewDisplayName: string;
}

export interface GetPlansResult {
    plans: GetPlansResultPlan[];
    usersOrganizationPlan: GetPlansResultPlan;
    planPrices: GetPlansResultPrice[];
    planFeatures: GetPlansResultFeature[];
    noRight: boolean;
    onPremise: boolean;
}

export interface GetPlansResultPlan {
    name: string;
    displayNameForWeb: string;
    displayName: string;
    isEnterprise: boolean;
    isFree: boolean;
    priceMonthly: number;
    priceType: string;
    cta: string;
    description: string;
    includedFeatures: string[];
    canUpgradeFrom: string[];
    canUpgradeTo: string[];
    descriptionForApp: string;
    isCurrent: boolean;
    isNextPlan: boolean;
    isFreeToUpgrade: boolean;
}

export interface GetPlansResultPrice {
    name: string;
    tiers: GetPlansResultPriceTier[];
}

export interface GetPlansResultPriceTier {
    tierName: string;
    stringValue: string;
}

export interface GetPlansResultFeature {
    name: string;
    tiers: GetPlansResultFeatureTier[];
}

export interface GetPlansResultFeatureTier {
    tierName: string;
    boolValue: boolean;
    stringValue: string;
}

export interface ChangeOrganizationLegalNameResult {
    organizationNewLegalName: string;
}

export interface ControlUpgradeLicenseResult {
    needOrganizazationName: boolean;
    messageBoxLines: string[];
    clickAction: string;
    newPlanName: string;
    price: number;
    kdv: number;
    newPlanDisplayName: string;
}

export interface UpgradeLicenseResult {
    isSuccessfull: boolean;
}

export interface GetLicenseInformationResult {
    endDate: string;
    freeLicenseCount: number;
    startDate: string;
    totalLicenseCount: number;
    usedLicenseCount: number;
    noRight: boolean;
    organizationDisplayName: string;
    organizationLegalName: string;
    isUserOrganizationOwnerUser: boolean;
    subscription: SignInUpResultSubscription;
    canBuyUserLicense: boolean;
    requiredPlanDisplayNameToBuyUserLicense: string;
    startDateString: string;
    endDateString: string;
    onPremise: boolean;
    apiUserCount: number;
    coreApiUserCount: number;
}

export interface CalculateLicensePriceResult {
    price: number;
    licensePricePerMonthDecimal: number;
    remainingMonths: number;
    priceForOneUserLicense: number;
    kdv: number;
    newLicenseCount: number;
    priceWithKdv: number;
}

export interface GetMyOrganizationRightsResult {
    items: GetMyOrganizationRightsResultItem[];
    isOrganizationOwner: boolean;
    isEnterprise: boolean;
}

export interface AddUserSignatureImageResult {
    addedRecordId: number;
}

export interface SetUserSignatureImageAsDefaultResult {
    oldDefaultItems: number[];
    updatedItemId: number;
}

export interface SaveUserSignatureImageResult {
    updatedItemId: number | null;
    deletedItemId: number | null;
}

export interface GetUserSignatureImagesResult {
    items: GetUserSignatureImagesResultItem[];
}

export interface GetUserSignatureImagesResultItem {
    imageData: string;
    id: number;
    isDefault: boolean;
}

export interface GetMyProfileInfoResult {
    eMail: string;
    displayName: string;
    organizationPlanDisplayName: string;
    organizationOwnerUserDisplayName: string;
    organizationOwnerUserEMail: string;
    organizationPlanIsEnterprise: boolean;
    accountCreatedDateString: string;
    organizationDisplayName: string;
    displayLanguage: string;
    accountCreatedDate: string;
    phoneNumber: string;
    twoFactorAuthenticationType: TwoFactorAuthenticationTypeEnum;
    twoFactorAuthenticationTypeString: string;
    gsmOperator: string;
}

export interface GetMyOrganizationRightsResultItem {
    id: number;
    role: OrganizationUserRightEnum;
    createdDate: string;
    roleString: string;
    createdDateString: string;
}

export interface GetUsersResult {
    users: GetUsersResultUser[];
    noRight: boolean;
    isEnterprise: boolean;
}

export interface TestScribanResult {
    ok: boolean;
    testResult: string;
}

export interface GetSftpTargetsResult {
    sftpTargets: GetSftpTargetsResultSftpTarget[];
    noRight: boolean;
    userPlanContainsSftp: boolean;
}

export interface GetOutgoingKepMessagesResult {
    items: GetOutgoingKepMessagesResultItem[];
}

export interface GetOutgoingKepMessagesResultItem {
    id: number;
    senderKepAccount_Id: number;
    senderKepAccount_DisplayName: string;
    senderKepAccount_KEPProvider: KEPProviderEnum;
    senderKepAccount_KepMailAddress: string;
    flow_Subject: string;
    outgoingKepMessageStatus: OutgoingKepMessageStatusEnum;
    createdDate: string;
    tos: GetOutgoingKepMessagesResultContact[];
    outgoingKepMessageStatusString: string;
}

export interface GetOutgoingKepMessagesResultContact {
    contactType: ContactTypeEnum;
    fullName: string;
}

export interface GetKepAccountsResult {
    kepAccounts: GetKepAccountsResultKepAccount[];
    noRight: boolean;
    userPlanContainsKep: boolean;
}

export interface GetApiUsersResult {
    noRight: boolean;
    userPlanContainsKep: boolean;
    apiUsers: GetApiUsersResultApiUser[];
    userPlanContainsApi: boolean;
    userPlanContainsCoreApi: boolean;
}

export interface GetKepAccountsForSendingResult {
    kepAccounts: GetKepAccountsForSendingResultKepAccount[];
    noRight: boolean;
    userPlanContainsKep: boolean;
}

export interface SaveOrganizationalRightResult {
    createdDate: string;
    id: number;
    userId: number;
    userDisplayName: string;
    userEMail: string;
    roleString: string;
    isDeleted: boolean;
    role: OrganizationUserRightEnum;
    organizationId: number;
    isActive: boolean;
}

export interface UpdateUsersTwoFactorAuthenticationResult {
    needToVerifySmsCode: boolean;
    newTwoFactorAuthenticationType: TwoFactorAuthenticationTypeEnum;
    newTwoFactorAuthenticationTypeString: string;
    twoFactorOperationName: string;
    twoFactorPhone: string;
}

export interface SaveUserResult {
    id: number;
    eMail: string;
    displayName: string;
    isActive: boolean;
    citizenshipNoMasked: string;
    phoneNumber: string;
}

export interface SaveSftpTargetResult {
    id: number;
    displayName: string;
    serverAddress: string;
    serverPort: number;
    username: string;
    fingerprint: string;
    targetFolder: string;
    createFolderFormat: string;
    changeFilenameFormat: string;
    useSsl: boolean;
    maxFileSizeInMbs: number | null;
    isDeleted: boolean;
    isActive: boolean;
    password: string;
}

export interface SaveKepAccountResult {
    id: number;
    displayName: string;
    kepProvider: KEPProviderEnum;
    kepMailAddress: string;
    pttCitizenshipNumber: string;
    pttPassKey: string;
    pttPassword: string;
    turkKepOfficerId: string;
    turkKepPassword: string;
    tnbKepAccountName: string;
    isDeleted: boolean;
    isActive: boolean;
    tnbKepAppName: string;
    tnbKepAppPassword: string;
    tnbKepPassword: string;
    ownerUserDisplayName: string;
    ownerUserEMail: string;
    useForGettingEvidence: boolean;
    thereIsNoUseForGettingEvidenceWarning: boolean;
}

export interface SetKepAccountAsUseForGettingEvidenceResult {
    id: number;
    useForGettingEvidenceFalseItems: number[];
}

export interface CheckAzureUserResult {
    resetCodeSentSuccessfully: boolean;
}

export interface ResetUserPasswordResult {
    resetCodeSentSuccessfully: boolean;
}

export interface AddUserResult {
    id: number;
    eMail: string;
    displayName: string;
    isActive: boolean;
    citizenshipNoMasked: string;
}

export interface AddSftpTargetResult {
    id: number;
    eMail: string;
    displayName: string;
    isActive: boolean;
}

export interface AddKepAccountResult {
    id: number;
    displayName: string;
    kepProvider: KEPProviderEnum;
    kepMailAddress: string;
    pttCitizenshipNumber: string;
    pttPassKey: string;
    pttPassword: string;
    turkKepOfficerId: string;
    turkKepPassword: string;
    tnbKepAccountName: string;
    isDeleted: boolean;
    isActive: boolean;
    tnbKepAppName: string;
    tnbKepAppPassword: string;
    tnbKepPassword: string;
    ownerUserDisplayName: string;
    ownerUserEMail: string;
    useForGettingEvidence: boolean;
}

export interface AddApiUserResult {
    id: number;
    name: string;
    allowedIP: string;
    isActive: boolean;
    isDeleted: boolean;
    apiKey: string;
    internalName: string;
    apiUserType: ApiUserTypeEnum;
    apiUserTypeString: string;
}

export interface SaveApiUserResult {
    id: number;
    name: string;
    allowedIp: string;
    isActive: boolean;
    isDeleted: boolean;
    internalName: string;
    apiUserType: ApiUserTypeEnum;
    apiUserTypeString: string;
}

export interface GetUsersResultUser {
    id: number;
    eMail: string;
    displayName: string;
    isActive: boolean;
    citizenshipNoMasked: string;
    phoneNumber: string;
}

export interface GetSftpTargetsResultSftpTarget {
    id: number;
    displayName: string;
    serverAddress: string;
    serverPort: number;
    username: string;
    fingerprint: string;
    targetFolder: string;
    createFolderFormat: string;
    changeFilenameFormat: string;
    useSsl: boolean;
    maxFileSizeInMbs: number | null;
    isDeleted: boolean;
    isActive: boolean;
    password: string;
}

export interface GetApiUsersResultApiUser {
    id: number;
    name: string;
    allowedIp: string;
    isDeleted: boolean;
    isActive: boolean;
    internalName: string;
    apiUserType: ApiUserTypeEnum;
    apiUserTypeString: string;
}

export interface GetKepAccountsResultKepAccount {
    id: number;
    displayName: string;
    kepProvider: KEPProviderEnum;
    kepMailAddress: string;
    pttCitizenshipNumber: string;
    pttPassKey: string;
    pttPassword: string;
    turkKepOfficerId: string;
    turkKepPassword: string;
    tnbKepAccountName: string;
    isDeleted: boolean;
    isActive: boolean;
    tnbKepAppName: string;
    tnbKepAppPassword: string;
    tnbKepPassword: string;
    ownerUserDisplayName: string;
    ownerUserEMail: string;
    useForGettingEvidence: boolean;
}

export interface GetKepAccountsForSendingResultKepAccount {
    id: number;
    displayName: string;
    kepProvider: KEPProviderEnum;
    kepMailAddress: string;
    kepProviderString: string;
}

export interface GetStartedByMeResult {
    getOnlyCountResult: number | null;
    items: GetStartedByMeResultItem[];
}

export interface GetNeedsToSignedByMeResult {
    getOnlyCountResult: number | null;
    items: GetNeedsToSignedByMeResultItem[];
}

export interface GetNeedsToSignedByMeResultItem {
    subject: string;
    flowUniqueId: string;
    flowItemCount: number;
    finishedFlowItemCount: number;
    createdDate: string;
    flowStartDate: string | null;
    status: number;
    departmentId: number | null;
    departmentDisplayName: string | null;
    userId: number;
    userEMail: string;
    lastOperationDate: string | null;
    lastOperationDateString: string;
    userDisplayName: string;
    completedRatio: any;
    statusString: string;
    sendDate: string | null;
    createdDateString: string;
    flowStartDateString: string;
    sendDateString: string;
}

export interface GetStartedByMeResultItem {
    subject: string;
    flowUniqueId: string;
    flowItemCount: number;
    finishedFlowItemCount: number;
    createdDate: string;
    flowStartDate: string | null;
    status: number;
    departmentId: number | null;
    departmentDisplayName: string | null;
    userId: number;
    userEMail: string;
    lastOperationDate: string | null;
    lastOperationDateString: string;
    userDisplayName: string;
    completedRatio: any;
    statusString: string;
    sendDate: string | null;
    createdDateString: string;
    flowStartDateString: string;
    sendDateString: string;
    waitingUserEMail: string;
    waitingUserDisplayName: string;
    waitingUserSendDate: string | null;
    waitingUserSendDateString: string;
}

export interface GetOrganizationRolesResult {
    items: GetOrganizationRolesResultItem[];
    noRight: boolean;
    isEnterprise: boolean;
}

export interface GetContactsForManagementResult {
    items: GetContactsForManagementResultItem[];
    pagingResult: PagingResult;
}

export interface PagingResult {
    totalItemCount: number;
    itemCountInPage: number;
    totalPageCount: number;
    currentPage: number;
    orderByColumn: string;
    sortOrder: string;
}

export interface GetContactsForManagementResultItem {
    id: number;
    workingCompanyName: string;
    departmentId: number | null;
    email: string;
    fullName: string;
    isSelected: boolean;
    kepAddress: string;
    contactType: ContactTypeEnum;
    contactTypeString: string;
    userId: number | null;
    taxNo: string;
    departmentName: string;
    kkk: string;
    mersisNo: string;
    citizenshipNo: string;
}

export interface GetTagsResult {
    items: GetTagsResultItem[];
}

export interface GetTagsResultItem {
    id: number;
    color: string;
    name: string;
    isSelected: boolean;
}

export interface AddTagResult {
    tagId: number;
    flowTagId: number;
    color: string;
    name: string;
    isSelected: boolean;
}

export interface RemoveTagResult {
    tagId: number;
    flowTagId: number;
}

export interface GetContactsResult {
    items: GetContactsResultItem[];
}

export interface GetContactsResultItem {
    contactId: number | null;
    userId: number | null;
    detsisDepartmentId: number | null;
    workingCompanyName: string;
    departmentId: number | null;
    email: string;
    fullName: string;
    isSystemUser: boolean;
    isSelected: boolean;
    kepAddress: string;
    citizenshipNo: string;
    hasCitizenshipNo: boolean;
    contactType: ContactTypeEnum | null;
}

export interface DivvySignInResult {
    isSuccessfull: boolean;
}

export interface DivvyGetFilesResult {
    toplamKayitSayisi: number;
    dosyaListesi: DivvyGetFilesResultDosya[];
    getNewToken: boolean;
}

export interface DivvyGetFilesResultDosyaRights {
    dosyaAcma: boolean;
}

export interface DivvyGetFilesResultDosya {
    anahtarAlan: string;
    boyut: string;
    dosyaAd: string;
    dosyaHash: string;
    dosyaKilitliMi: boolean;
    dosyaParcalari: string;
    dosyaTempID: string;
    dosyaTuru: string;
    engineNo: number;
    klasorYetkileri: DivvyGetFilesResultDosyaRights;
    exifBilgisiOlusturulduMu: boolean;
    gercekBoyut: number;
    dosyaId: string;
    indexBilgisiGirildiMi: boolean;
    indexKarakterSayisi: number;
    kategori: string;
    klasorRef: number;
    medyaMi: boolean;
    kriptoluMu: boolean;
    parcaSayisi: number;
    serverAdi: string;
    silindi: boolean;
    tarih: string;
    tekParcaMi: boolean;
    thumbnailBuyukYolu: string;
    thumbnailYolu: string;
    versiyon: number;
    isSelected: boolean;
    canBeAdded: boolean;
}

export interface DivvyDownloadFileResult {
    isSuccessful: boolean;
    getNewToken: boolean;
    flowDocumentId: number;
}

export interface DivvyGetFoldersResult {
    klasorSema: DivvyGetFoldersResultKlasor[];
    toplamKayitSayisi: number;
    dosyaListesi: DivvyGetFilesResultDosya[];
    getNewToken: boolean;
}

export interface DivvyGetFoldersResultKlasor {
    extensionData: DivvyGetFoldersResultKlasorExtension;
    arsivGruplariRef: string;
    erisimSifresi: string;
    grupID: string;
    grupRef: number;
    hizmetAlinacakSunucuAdresi: any;
    klasorId: number;
    islemZamani: string;
    kaydiOlusturanKullaniciRef: number;
    klasorAdi: string;
    klasorYetkileri: DivvyGetFoldersResultKlasorRights;
    maksimumKota: number;
    olusturmaTarihi: string;
    paylasildiMi: boolean;
    silindi: boolean;
    ustGrupId: string;
    ustID: number;
    ustKlasorAdi: any;
    clsTicket: any;
}

export interface DivvyGetFoldersResultKlasorExtension {
    myProperty: number;
}

export interface DivvyGetFoldersResultKlasorRights {
    extensionData: DivvyGetFoldersResultKlasorExtension;
    dosyaAcma: boolean;
    dosyaAdiniListeleyebilme: boolean;
    dosyaArsivGuncelleme: boolean;
    dosyaEkleme: boolean;
    dosyaGecmisiniGorebilme: boolean;
    dosyaGuncelleme: boolean;
    dosyaKopyalama: boolean;
    dosyaMaksimumVersiyonSayisi: number;
    dosyaMetaDataBilgileriniGorebilme: boolean;
    dosyaMetaDataBilgileriniGuncelleme: boolean;
    dosyaSablonlari: boolean;
    dosyaSilme: boolean;
    dosyaTasima: boolean;
    dosyaVersiyonlariniGorebilme: boolean;
    klasorAdiniDegistirme: boolean;
    klasorEkleme: boolean;
    klasorGecmisiniGorebilme: boolean;
    klasorKopyalama: boolean;
    klasorMetaBilgileriniGorebilme: boolean;
    klasorSilme: boolean;
    klasorTasima: boolean;
    klasoruGorebilenKullanicilariListeleyebilme: boolean;
    kurumIciDosyaPaylasimi: boolean;
    kurumIciKlasorPaylasimi: boolean;
    linkleDosyaPaylasimi: boolean;
    linkleKlasorPaylasimi: boolean;
    silinenDosyalariKurtarabilme: boolean;
    silinenKlasorleriKurtarabilme: boolean;
    silinmisDosyaArayabilme: boolean;
    silinmisKlasorArayabilme: boolean;
}

export interface GetDepartmentsResult {
    departments: GetDepartmentsResultDepartment[];
    noRight: boolean;
    isEnterprise: boolean;
}

export interface GetDepartmentsWithMyRolesResult {
    departments: GetDepartmentsWithMyRolesResultDepartment[];
    isOrganizationOwner: boolean;
}

export interface AddChildDepartmentResult {
    newDepartmentId: number;
    newDepartmentDisplayName: string;
    newDepartmentParentId: number | null;
}

export interface RenameDepartmentResult {
    newDisplayName: string;
    departmentId: number;
}

export interface DeleteDepartmentResult {
    departmentId: number;
}

export interface GetDepartmentsResultDepartment {
    id: number;
    parentId: number | null;
    displayName: string;
    children: GetDepartmentsResultDepartment[];
    isSelected: boolean;
    inEditState: boolean;
    departmentUsersCount: number;
}

export interface GetOrganizationRolesResultItem {
    id: number;
    isActive: boolean;
    role: OrganizationUserRightEnum;
    userDisplayName: string;
    userEMail: string;
    userId: number;
    roleString: string;
}

export interface DepartmentUserRightInfo {
    departmentId: number;
    role: DepartmentUserRightEnum;
    affectsChildren: boolean;
    isCreatedByParent: boolean;
}

export interface DepartmentUserRightWithUserInfo {
    departmentId: number;
    role: DepartmentUserRightEnum;
    affectsChildren: boolean;
    isCreatedByParent: boolean;
    userDisplayName: string;
    departmentUserId: number | null;
    inheritedParentId: number | null;
    roleString: string;
    departmentName: string;
    userEMail: string;
}

export interface GetDepartmentsWithMyRolesResultDepartment {
    id: number;
    parentId: number | null;
    displayName: string;
    children: GetDepartmentsWithMyRolesResultDepartment[];
    isSelected: boolean;
    inEditState: boolean;
    departmentUsersCount: number;
    roles: GetDepartmentsWithMyRolesResultDepartmentUser[];
}

export interface GetDepartmentsWithMyRolesResultDepartmentUser {
    id: number;
    affectsChildren: boolean;
    role: DepartmentUserRightEnum;
}

export interface GetDepartmentUserRightEnumsResult {
    enums: EnumItem[];
}

export interface GetFlowItemStatusEnumsResult {
    enums: EnumItem[];
}

export interface GetOrganizationUserRightEnumsResult {
    enums: EnumItem[];
}

export interface GetContactTypeEnumsResult {
    enums: EnumItem[];
}

export interface GetApiUserTypeEnumsResult {
    enums: EnumItem[];
}

export interface GetFlowStatusEnumsResult {
    enums: EnumItem[];
}

export interface GetFlowItemTypeEnumsResult {
    enums: EnumItem[];
}

export interface GetKepProviderTypeEnumsResult {
    enums: EnumItem[];
}

export interface GetTwoFactorAuthenticationTypeEnumsResult {
    enums: EnumItem[];
}

export interface GetFlowItemGroupAuthorityTypeEnumsResult {
    enums: EnumItem[];
}

export interface GetOutgoingKepMessageStatusEnumsResult {
    enums: EnumItem[];
}

export interface GetSftpLogTypeEnumsResult {
    enums: EnumItem[];
}

export interface GetSubscriptionUpgradeUpgradeTypeEnumsResult {
    enums: EnumItem[];
}

export interface GetMyDepartmentRolesResult {
    items: GetMyDepartmentRolesResultItem[];
    isEnterprise: boolean;
}

export interface ChangeLanguageResult {
    newLanguage: string;
}

export interface GetMyDepartmentRolesResultItem {
    affectsChildren: boolean;
    createdDate: string;
    departmentId: number;
    id: number;
    role: DepartmentUserRightEnum;
    userId: number;
    userDisplayName: string;
    userEMail: string;
    roleString: string;
    departmentName: string;
    createdDateString: string;
}

export interface GetDepartmentUsersResult {
    departmentUsers: GetDepartmentUsersResultItem[];
    noRightForManageDepartments: boolean;
    departmentUserRightInfos: DepartmentUserRightWithUserInfo[];
    isEnterprise: boolean;
    noRightForManageUsers: boolean;
}

export interface GetDepartmentUsersResultItem {
    affectsChildren: boolean;
    createdDate: string;
    departmentId: number;
    id: number;
    role: DepartmentUserRightEnum;
    userId: number;
    userDisplayName: string;
    userEMail: string;
    roleString: string;
    isDeleted: boolean;
    isActive: boolean;
}

export interface AddDepartmentUserResult {
    affectsChildren: boolean;
    createdDate: string;
    departmentId: number;
    id: number;
    role: DepartmentUserRightEnum;
    userId: number;
    userDisplayName: string;
    userEMail: string;
    roleString: string;
    isDeleted: boolean;
    isActive: boolean;
}

export interface AddContactResult {
    id: number;
    workingCompanyName: string;
    departmentId: number | null;
    email: string;
    fullName: string;
    kepAddress: string;
    contactType: ContactTypeEnum;
    contactTypeString: string;
    userId: number | null;
    taxNo: string;
    kkk: string;
    mernisNo: string;
    citizenshipNo: string;
}

export interface SaveContactResult {
    id: number;
    workingCompanyName: string;
    departmentId: number | null;
    email: string;
    fullName: string;
    kepAddress: string;
    contactType: ContactTypeEnum;
    contactTypeString: string;
    userId: number | null;
    taxNo: string;
    kkk: string;
    mersisNo: string;
    citizenshipNo: string;
}

export interface AddOrganizationalRoleResult {
    createdDate: string;
    id: number;
    userId: number;
    userDisplayName: string;
    userEMail: string;
    roleString: string;
    isDeleted: boolean;
    role: OrganizationUserRightEnum;
    organizationId: number;
    isActive: boolean;
}

export interface SaveDepartmentUserResult {
    affectsChildren: boolean;
    createdDate: string;
    departmentId: number;
    id: number;
    role: DepartmentUserRightEnum;
    userId: number;
    userDisplayName: string;
    userEMail: string;
    roleString: string;
    isDeleted: boolean;
    isActive: boolean;
}

export interface SignUpWithTemplateRequest extends BaseRequest {
    anonymousSessionId: string;
    eMail: string;
    fullname: string;
    saveTemplateRequest: SaveTemplateRequest;
}

export interface GetTemplateDesignerInfoRequest extends BaseRequest {
    anonymousSessionId: string;
}

export interface GetDocumentsRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    getAttachments: boolean;
}

export interface GetDocumentsForViewingRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface GetSecretForDocumentRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    documentId: number;
    documentType: string;
}

export interface GetDocumentRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    documentId: number;
}

export interface GetDocumentResult {
    flowId: number;
    document: GetDocumentsResultDocument;
}

export interface SignUpWithTemplateResult {
    idToken: string;
    signingPortalUrl: string;
    flowId: number;
    loginSessionId: string;
    loginShortCode: string;
}

export interface GetTemplateDesignerInfoResult {
    files: GetTemplateDesignerInfoResultFlowFile[];
    flowItems: GetFlowForEditingTemplateResultFlowItem[];
    flowAccessToken: string;
    signatureFieldWidth: number;
    signatureFieldHeight: number;
    qrCodeMode: QrCodeModeEnum;
    confirmationMode: ConfirmationModeEnum;
    qrCodeBottom: number | null;
    qrCodeTop: number | null;
    qrCodeLeft: number | null;
    qrCodeRight: number | null;
    qrCodeTransformOrigins: string;
    lawStringBottom: number | null;
    lawStringTop: number | null;
    lawStringLeft: number | null;
    lawStringRight: number | null;
    lawStringTransformOrigins: string;
}

export interface GetTemplateDesignerInfoResultFlowFile {
    documentName: string;
    pageCount: number;
    pages: GetTemplateDesignerInfoResultFlowPage[];
    anonymousSessionId: string;
}

export interface GetTemplateDesignerInfoResultFlowPage {
    width: number;
    height: number;
    pageNo: number;
    documentName: string;
    totalPageCount: number;
    signatures: GetTemplateDesignerInfoResulSignature[];
}

export interface GetTemplateDesignerInfoResulSignature {
    id: number;
    width: number;
    height: number;
    x: number;
    y: number;
    pageNo: number;
    documentFolder: string;
    flowItem: GetFlowForEditingTemplateResultFlowItem;
}

export interface GetSecretForDocumentResult {
    secret: string;
    isPdf: boolean;
}

export interface GetDocumentsResult {
    flowId: number;
    documents: GetDocumentsResultDocument[];
}

export interface GetDocumentsForViewingResult {
    flowId: number;
    documents: GetDocumentsForViewingResultDocument[];
}

export interface GetDocumentsForViewingResultDocument {
    documentName: string;
    id: number;
    extension: string;
    orderNo: number;
    documentType: string;
}

export interface GetDocumentsResultDocument {
    documentName: string;
    id: number;
    extension: string;
    orderNo: number;
    documentType: string;
    size: number;
    pageCount: number;
}

export interface ApiUploadResult {
    result: UploadResult;
    error: string;
}

export interface GetFlowForEditingTemplateRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface GetFlowForEditingTemplateResult {
    flowId: number;
    files: GetFlowForEditingTemplateResultFlowFile[];
    flowItems: GetFlowForEditingTemplateResultFlowItem[];
    flowAccessToken: string;
    signatureFieldWidth: number;
    signatureFieldHeight: number;
    qrCodeMode: QrCodeModeEnum;
    confirmationMode: ConfirmationModeEnum;
    qrCodeBottom: number | null;
    qrCodeTop: number | null;
    qrCodeLeft: number | null;
    qrCodeRight: number | null;
    qrCodeTransformOrigins: string;
    lawStringBottom: number | null;
    lawStringTop: number | null;
    lawStringLeft: number | null;
    lawStringRight: number | null;
    lawStringTransformOrigins: string;
    flowType: FlowTypeEnum;
    signatureType: SignatureTypeEnum;
}

export interface GetFlowForEditingTemplateResultFlowFile {
    id: number;
    documentName: string;
    pageCount: number;
    pages: GetFlowForEditingTemplateResultFlowPage[];
}

export interface GetFlowForEditingTemplateResultFlowPage {
    id: number;
    width: number;
    height: number;
    pageNo: number;
    documentName: string;
    totalPageCount: number;
    signatures: GetFlowForEditingTemplateResultSignature[];
    freeTextBoxes: GetFlowForEditingTemplateResultFreeTextBox[];
}

export interface GetFlowForEditingTemplateResultSignature {
    id: number;
    width: number;
    height: number;
    x: number;
    y: number;
    pageId: number;
    documentFolder: string;
    flowItem: GetFlowForEditingTemplateResultFlowItem;
}

export interface GetFlowForEditingTemplateResultFreeTextBox {
    id: number;
    width: number;
    height: number;
    x: number;
    y: number;
    pageId: number;
    documentFolder: string;
    content: string;
}

export interface GetFlowForEditingTemplateResultFlowItem {
    id: number;
    eMail: string;
    fullname: string;
    orderNo: number;
    color: string;
    flowItemType: FlowItemTypeEnum;
    groupAuthorityType: FlowItemGroupAuthorityTypeEnum;
    groupNo: number;
    groupAuthorityTypeString: string;
    flowItemTypeString: string;
}

export interface GetFlowHistoryRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface GetFlowHistoryResult {
    items: GetFlowHistoryResultItem[];
}

export interface GetFlowHistoryResultItem {
    id: number;
    operatorFlowItemId: number | null;
    operatorUserId: number | null;
    createdDate: string;
    flowHistoryType: FlowHistoryTypeEnum;
    flowId: number;
    clientIP: string;
    clientUserAgent: string;
    flowDocumentId: number | null;
    usedFlowAccessTokenId: number | null;
    usedSignerTokenId: number | null;
    flowDocumentPageId: number | null;
    nextFlowFlowItemId: number | null;
    operatorFlowItem_OrderNo: number | null;
    operatorFlowItem_eMail: string;
    operatorFlowItem_Fullname: string;
    operatorFlowItem_Color: string;
    operatorFlowItem_UserId: number | null;
    operatorUser_EMail: string;
    operatorUser_DisplayName: string;
    operatorFlowItem_DeclineNote: string;
    flowDocument_DocumentName: string;
    flowDocument_Extension: string;
    flowDocument_OrderNo: number | null;
    flowDocumentPage_PageNo: number | null;
    nextFlowItem_OrderNo: number | null;
    nextFlowItem_eMail: string;
    nextFlowItem_Fullname: string;
    nextFlowItem_Color: string;
    nextFlowItem_UserId: number | null;
    createdDateString: string;
    flowHistoryTypeString: string;
}

export interface GetFlowsWhichWillBeDeletedRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetFlowItemsForViewRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface GetFlowMetadataRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface GetFlowMetadataResult {
    flowId: number;
    flowSubject: string;
    flowStatusString: string;
    flowCreatedDate: string;
    flowStartDate: string;
    flowTotalSizeOfOriginalFiles: number;
    totalSizeOfGeneratedFileSize: number;
    flowUniqueId: string;
    flowStatus: FlowStatusEnum;
    confirmationMode: ConfirmationModeEnum;
    confirmationModeString: string;
    verificationCode: string;
    verificationCodeUrl: string;
    isFilesDeleted: boolean;
    departmentDisplayName: string;
    willBeDeletedOnString: string;
    flowTypeMode: FlowTypeEnum;
    flowTypeString: string;
    signatureType: SignatureTypeEnum;
    signatureTypeString: string;
}

export interface GetFlowsWhichWillBeDeletedResult {
    items: GetFlowsWhichWillBeDeletedResultItem[];
    isFree: boolean;
}

export interface GetFlowsWhichWillBeDeletedResultItem {
    status: FlowStatusEnum;
    createdDate: string;
    userId: number;
    userEMail: string;
    organizationId: number;
    organizationDisplayName: string;
    organizationPlan: string;
    subject: string;
    uniqueId: string;
    userDisplayName: string;
    organizationOwnerUserId: number;
    finishedDate: string | null;
    id: number;
    departmentId: number | null;
    departmentDisplayName: string;
    willBeDeletedAtString: string;
    statusString: string;
}

export interface GetFlowItemsForViewResult {
    flowId: number;
    flowItems: GetFlowItemsForViewResultFlowItem[];
    signingPortalUrl: string;
    flowType: FlowTypeEnum;
}

export interface GetFlowItemsForViewResultFlowItem {
    id: number;
    eMail: string;
    fullname: string;
    orderNo: number;
    color: string;
    status: FlowItemStatusEnum;
    statusString: string;
    signedDate: string | null;
    sendDate: string | null;
    sendDateString: string;
    isMenuOpen: boolean;
    signedDateString: string;
    waitDurationString: string;
    signerIsCurrentUser: boolean;
    groupNo: number;
    groupAuthorityType: FlowItemGroupAuthorityTypeEnum;
    flowItemType: FlowItemTypeEnum;
    groupAuthorityTypeString: string;
    flowItemTypeString: string;
    isMobileSign: boolean | null;
}

export interface GetFlowItemsForViewResultFlowFile {
    id: number;
    documentName: string;
    pageCount: number;
    pages: GetFlowItemsForViewResultFlowPage[];
    orderNo: number;
    pagesToShow: number;
}

export interface GetFlowItemsForViewResultFlowPage {
    id: number;
    width: number;
    height: number;
    pageNo: number;
    documentName: string;
    totalPageCount: number;
    signatures: GetFlowItemsForViewResultSignature[];
}

export interface GetFlowItemsForViewResultSignature {
    id: number;
    width: number;
    height: number;
    x: number;
    y: number;
    pageId: number;
    documentFolder: string;
    flowItem: GetFlowItemsForViewResultFlowItem;
}

export interface GetFlowItemsRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface GetFlowNotesRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    noteId: number | null;
}

export interface GetFlowNotesWithTokenRequest extends BaseRequest {
    flowId: number;
    token: string;
    noteId: number | null;
}

export interface GetFlowEYazismaTargetsRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface GetFlowTagsRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface GetFlowNotesResult {
    flowId: number;
    items: GetFlowNotesResultItem[];
}

export interface GetFlowNotesResultItem {
    id: number;
    attachmentFileName: string;
    createdDate: string;
    creatorFlowItem_Email: string;
    creatorUserId: number | null;
    creatorUser_DisplayName: string;
    creatorUser_EMail: string;
    isPublic: boolean;
    noteText: string;
}

export interface GetFlowItemsResult {
    flowId: number;
    flowItems: GetFlowItemsResultFlowItem[];
    flowType: FlowTypeEnum;
    signatureType: SignatureTypeEnum;
}

export interface GetFlowTagsResult {
    items: GetFlowTagsResultItem[];
}

export interface GetFlowEYazismaTargetsResult {
    items: FlowEYazismaTargetsResultItem[];
}

export interface GetFlowTagsResultItem {
    id: number;
    createdDate: string;
    creatorUserId: number;
    tagColor: string;
    tagCreatorUserId: number;
    tagCreatorUserDisplayName: string;
    tagName: string;
}

export interface FlowEYazismaTargetsResultItem {
    id: number;
    contactId: number;
    contactFullName: string;
    contactEmail: string;
}

export interface GetFlowItemsResultFlowItem {
    id: number;
    eMail: string;
    fullname: string;
    orderNo: number;
    color: string;
    groupNo: number;
    flowItemType: FlowItemTypeEnum;
    groupAuthorityType: FlowItemGroupAuthorityTypeEnum;
    citizenshipNo: string;
    userCitizenshipNo: string;
    hasCitizenshipNo: boolean;
    userId: number | null;
    deadline: string | null;
}

export interface GetFlowsRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number | null;
    getItemsNeedsToBeSignedByMe: boolean | null;
    searchParameters: GetFlowsRequest_SearchParameters;
}

export interface GetFlowsRequest_SearchParameters {
    searchString: string;
    createdDateStart: string | null;
    createdDateEnd: string | null;
    operationDateStart: string | null;
    operationDateEnd: string | null;
    tagIds: number[];
    assignedToUserId: number | null;
    assignedToEMail: string;
}

export interface GetFlowsResult {
    flows: GetFlowsResultFlow[];
    draftCount: number;
    runningCount: number;
    finishedCount: number;
    creditBalance: number;
    declinedCount: number;
}

export interface GetFlowsResultFlow {
    createdDateString: string;
    iyzicoPaymentResultId: number | null;
    status: number;
    subject: string;
    id: number;
    waitingFlowItemsCount: number;
    totalFlowItemsCount: number;
    lastOperationDateString: string;
    createdDate: string;
    statusString: string;
    totalSizeOfGeneratedFileSize: number;
    totalSizeOfOriginalFiles: number;
    pageCount: number;
    flowItemCount: number;
    finishedFlowItemCount: number;
    documentCount: number;
    flowStartDate: string | null;
    completedRatio: number | null;
    flowItems: GetFlowsResultFlowItem[];
    departmentId: number | null;
    departmentDisplayName: string | null;
    userId: number;
    userDisplayName: string;
    userEMail: string;
    flowUniqueId: string;
    flowStartDateString: string;
    isFilesDeleted: boolean;
    lastOperationDate: string | null;
    isSelected: boolean;
    flowItemType: FlowItemTypeEnum;
    flowItemTypeString: string;
}

export interface GetFlowsResultFlowItem {
    eMail: string;
    fullname: string;
    status: FlowItemStatusEnum;
    orderNo: number;
    statusString: string;
    color: string;
    sendDate: string | null;
    signedDate: string | null;
    sendDateString: string;
    signedDateString: string;
    waitDurationString: string;
    isMobileSign: boolean | null;
    flowItemType: FlowItemTypeEnum;
    flowItemTypeString: string;
}

export interface GetMoneyTransfersRequest extends BaseRequest {
    loginSessionId: string;
    moneyTransferStatus: MoneyTransferStatusEnum | null;
}

export interface DeclineMoneyTransferRequest extends BaseRequest {
    loginSessionId: string;
    moneyTransferId: number;
    declineReason: string;
}

export interface DeclineMoneyTransferResult {
    dummy: number;
}

export interface GetMoneyTransfersResult {
    items: GetMoneyTransfersResultItem[];
    moneyTransferStatuses: EnumItem[];
}

export interface EnumItem {
    key: number;
    name: string;
    description: string;
}

export interface GetMoneyTransfersResultItem {
    moneyTransferId: number;
    moneyTransferCreatedDate: string;
    moneyTransferStatusString: string;
    price: number;
    receiptAddressAddress: string;
    receiptAddressCityName: string;
    receiptAddressCompanyName: string;
    receiptAddressCompanyTaxNo: string;
    receiptAddressCompanyTaxOffice: string;
    receiptAddressCitizenshipNo: string;
    receiptAddressCountry: string;
    receiptAddressPersonFirstName: string;
    receiptAddressPersonLastName: string;
    receiptAddressZipCode: string;
    userEMail: string;
    userDisplayName: string;
    purchasedPackageName: string;
    tag: string;
    receiptNumber: string;
    receiptPdfCreationDate: string | null;
    moneyTransferStatus: MoneyTransferStatusEnum;
    priceKdv: number;
    userLicenseCount: number | null;
    transferCode: string;
    description: string;
    priceString: string;
    priceKdvString: string;
}



export interface GetPaymentAmountRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface GetPaymentAmountResult {
    paymentAmount: string;
}





export interface GetPaymentsPayTrRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetPaymentsPayTrResult {
    items: GetPaymentsPayTrResultItem[];
    noRight: boolean;
    isFreePlan: boolean;
}

export interface GetPaymentsPayTrResultItem {
    id: number;
    createdDate: string;
    paymentType: PayTrPaymentTypeEnum;
    subject: string;
    uniqueId: string;
    receipt_Id: number;
    flowId: number | null;
    receiptPdfCreationDate: string | null;
    amount: number;
    lastFourDigits: string;
    createdDateString: string;
    paidPriceString: string;
    paymentTypeString: string;
}

export interface GetPayTrPaymentsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetOrganizationsRequest extends BaseRequest {
    loginSessionId: string;
    organizationFilter: string;
    planFilter: string;
    eMailFilter: string;
}

export interface SendEMailToOrganizationsRequest extends BaseRequest {
    loginSessionId: string;
    organizationIds: number[];
    mailSubject: string;
    mailBody: string;
    mailAddresses: string;
}

export interface GetAllSettingsRequest extends BaseRequest {
    loginSessionId: string;
}

export interface CreateSubscriptionUpgradeRequest extends BaseRequest {
    loginSessionId: string;
    organizationId: number;
    subscriptionId: number;
    creatorNote: string;
    upgradeType: SubscriptionUpgradeUpgradeTypeEnum;
    newValue: string;
}

export interface ApproveOrDenySubscriptionUpgradeRequest extends BaseRequest {
    loginSessionId: string;
    organizationId: number;
    planUpgradeRequestId: string;
    subscriptionUpgradeId: number;
    approverNote: string;
    passwordForOperation: string;
    approveOrDeny: boolean;
    subscriptionId: number;
}

export interface ReportUserByLoginCountRequest extends BaseRequest {
    loginSessionId: string;
}

export interface ReportUserByFlowCountRequest extends BaseRequest {
    loginSessionId: string;
    flowStatusEnum: FlowStatusEnum;
}

export interface ReportUserLoginRequest extends BaseRequest {
    loginSessionId: string;
    userId: number | null;
    userEMail: string | null;
}

export interface ReportUserDetailsRequest extends BaseRequest {
    loginSessionId: string;
    userId: number | null;
    userEMail: string | null;
}

export interface ReportNewRegistarsRequest extends BaseRequest {
    loginSessionId: string;
    date: string;
}

export interface ApproveOrDenySubscriptionUpgradeResult {
    planUpgradeRequestId: string;
    savedToDb: boolean;
    eMailSent: boolean;
}

export interface ReportUserByLoginCountResult {
    items: ReportUserByLoginCountResultItem[];
}

export interface ReportUserByLoginCountResultItem {
    userId: number;
    eMail: string;
    loginCount: number;
    lastLoginDate: string;
}

export interface ReportUserByFlowCountResult {
    items: ReportUserByFlowCountResultItem[];
}

export interface ReportUserByFlowCountResultItem {
    eMail: string;
    flowCount: number;
}

export interface ReportUserDetailsResult {
    user: ReportUserDetailsResultUser;
    organization: ReportUserDetailsResultOrganization;
    flows: ReportUserDetailsResultFlow[];
}

export interface ReportUserDetailsResultUser {
    createdDate: string;
    displayName: string;
    eMail: string;
    id: number;
    isActive: boolean;
    isDeleted: boolean;
    lastLoginDate: string;
}

export interface ReportUserDetailsResultOrganization {
    createdDate: string;
    creditBalance: number;
    displayName: string;
    id: number;
    legalName: string;
    plan: string;
}

export interface ReportUserDetailsResultFlow {
    id: number;
    uniqueId: string;
    status: FlowStatusEnum;
    documentCount: number;
    pageCount: number;
    flowItemCount: number;
    flowStartDate: string | null;
    finishedDate: string | null;
    iyzicoPaymentResultId: number | null;
    userCreditTransactionId: number | null;
    createdDate: string;
    isDeleted: boolean;
    deletedDate: string | null;
    isFilesDeleted: boolean;
    flowType: FlowTypeEnum;
    signatureType: SignatureTypeEnum;
    statusDescription: string;
    flowTypeDescription: string;
    signatureTypeDescription: string;
    subject: string;
}

export interface ReportNewRegistarsResult {
    items: ReportReportNewRegistarsResultItem[];
}

export interface ReportReportNewRegistarsResultItem {
    createdDate: string;
    displayName: string;
    eMail: string;
    id: number;
    isActive: boolean;
    isDeleted: boolean;
    lastLoginDate: string;
}

export interface ReportUserLoginResult {
    items: ReportUserLoginResultItem[];
}

export interface ReportUserLoginResultItem {
    clientIP: string;
    organizationDisplayName: string;
    createdDate: string;
    clientUserAgent: string;
    eMail: string;
    displayName: string;
    organizationLegalName: string;
    id: number;
}

export interface CreateSubscriptionUpgradeResult {
    savedToDb: boolean;
    eMailSent: boolean;
}

export interface GetAllSettingsResult {
    allSettings: string[];
}

export interface SendEMailToOrganizationsResult {
    eMails: string[];
}

export interface GetOrganizationsResult {
    items: GetOrganizationsResultItem[];
    plans: GetPlansResultPlan[];
}

export interface GetOrganizationsResultItem {
    createdDate: string;
    displayName: string;
    id: number;
    legalName: string;
    ownerUserId: number;
    plan: string;
    subscriptions: GetOrganizationsResultSubscription[];
    ownerUserDisplayName: string;
    ownerUserEMail: string;
}

export interface GetOrganizationsResultUpgrade {
    approvedDate: string | null;
    createdDate: string;
    approverNote: string;
    approverUserId: number | null;
    creatorNote: string;
    newValue: string;
    creatorUserId: number;
    status: SubscriptionUpgradeStatusEnum;
    upgradeType: SubscriptionUpgradeUpgradeTypeEnum;
    id: number;
    upgradeTypeString: string;
    creatorUserDisplayName: string;
    creatorUserEMail: string;
    approverUserDisplayName: string;
    approverUserEMail: string;
    statusString: string;
    upgradeId: string;
}

export interface GetOrganizationsResultSubscription {
    id: number;
    totalLicenseCount: number;
    freeLicenseCount: number;
    startDate: string;
    endDate: string;
    planName: string;
    canCreateEyp1: number;
    canSendKEP: number;
    canSendSFTP: number;
    canSendToExternalUsers: number;
    canUseAdvancedFlow: number;
    isActive: boolean;
    subscriptionUpgrades: GetOrganizationsResultUpgrade[];
    totalApiLicenseCount: number;
    totalCoreApiLicenseCount: number;
    canUseUim: number;
}

export interface GetPayTrPaymentsResult {
    items: GetPayTrPaymentsResultItem[];
}

export interface GetPayTrPaymentsResultItem {
    receiptType: number;
    id: number;
    userEMail: string;
    userDisplayName: string;
    paymentType: number;
    amount: number;
    createdDate: string;
    payTrOid: string;
    payTrPaymentAmount: string;
    payTrStatus: string;
    payTrTotalAmount: string;
    purchasedPackageName: string;
    lastFourDigits: string;
    receiptTypeString: string;
    paymentTypeString: string;
    purchasedPackagePrice: number;
    purchasedPackagePriceKdv: number;
    amountKdv: number;
    userLicenseCount: number | null;
    createdDateString: string;
    receiptAddressCompanyTaxNo: string;
    receiptAddressCompanyTaxOffice: string;
    receiptAddressCitizenshipNo: string;
    receiptAddressCityName: string;
    receiptAddressCountry: string;
    receiptAddressAddress: string;
    receiptAddressPersonFirstName: string;
    receiptAddressPersonLastName: string;
    receiptAddressZipCode: string;
    receiptNumber: string;
    receiptPdfCreationDate: string | null;
    receiptId: number;
    isReceiptPdfCreated: boolean;
}

export interface GetPersonalUsageInfoRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetPersonalUsageInfoResult {
    flowCount: number;
    totalSizeOfOriginalFiles: number;
    totalSizeOfGeneratedFileSize: number;
}

export interface GetReceiptAddressesRequest extends BaseRequest {
    loginSessionId: string;
    receiptAddressId: number | null;
}

export interface GetReceiptAddressesResult {
    addresses: CheckReceiptAddressResultReceiptAddress[];
    noRight: boolean;
}

export interface CheckReceiptAddressResultReceiptAddress {
    id: number;
    userId: number;
    displayName: string;
    isCompany: boolean;
    personFirstName: string;
    personLastName: string;
    country: string;
    cityName: string;
    address: string;
    postalCode: string;
    companyName: string;
    companyTaxNo: string;
    citizenshipNo: string;
    companyTaxOffice: string;
    isDeleted: boolean;
    creatorUserId: number;
    zipCode: string;
}

export interface GetReceiptToGenerateRequest extends BaseRequest {
    loginSessionId: string;
}

export interface GetReceiptToGenerateResult {
    items: GetReceiptToGenerateResultItem[];
}

export interface GetReceiptToGenerateResultItem {
    receiptId: number;
    receiptCreatedDate: string;
    receiptType: string;
    price: number;
    receiptAddressAddress: string;
    receiptAddressCityName: string;
    receiptAddressCompanyName: string;
    receiptAddressCompanyTaxNo: string;
    receiptAddressCompanyTaxOffice: string;
    receiptAddressCitizenshipNo: string;
    receiptAddressCountry: string;
    receiptAddressPersonFirstName: string;
    receiptAddressPersonLastName: string;
    receiptAddressZipCode: string;
    receiptNumber: string;
    userEMail: string;
    userDisplayName: string;
    organizationDisplayName: string;
    priceKdv: number;
    priceString: string;
    priceKdvString: string;
}

export interface GetReceiptToGenerateResultItem2 {
    receipts_Id: number;
    receipts_ReceiptAddressId: number;
    receipts_ReceiptIdentifier: string;
    receipts_DataLength: number | null;
    receipts_ReceiptDate: string | null;
    receipts_ReceiptDateString: string;
    receipts_CreatedDate: string | null;
    receipts_CreatedDateString: string;
    users_EMail: string;
    receiptAddresses_DisplayName: string;
    receiptAddresses_IsCompany: boolean;
    receiptAddresses_PersonFirstName: string;
    receiptAddresses_PersonLastName: string;
    receiptAddresses_Country: string;
    receiptAddresses_CityName: string;
    receiptAddresses_ZipCode: string;
    receiptAddresses_Address: string;
    receiptAddresses_CompanyName: string;
    receiptAddresses_CompanyTaxNo: string;
    receiptAddresses_CitizenshipNo: string;
    receiptAddresses_CompanyTaxOffice: string;
    userCreditTransactions_FlowId: number | null;
    userCreditTransactions_Amount: number | null;
    userCreditTransactions_Description: string;
    userCreditTransactions_CreatedDate: string | null;
    userCreditTransactions_CreatedDateString: string;
    userCreditTransactions_IyzicoPaymentResultId: number | null;
    userCreditTransactions_MoneyTransferId: number | null;
    userCreditTransactions_TransactionType: number | null;
    userCreditTransactions_TransactionTypeString: string;
    moneyTransfers_Amount: number | null;
    moneyTransfers_Status: number | null;
    moneyTransfers_Description: string;
    moneyTransfers_CreatedDate: string | null;
    moneyTransfers_CreatedDateString: string;
    iyzicoPaymentResults_Id: number | null;
    iyzicoPaymentResults_CreatedDate: string | null;
    iyzicoPaymentResults_CreatedDateString: string;
    iyzicoPaymentResults_Status: string;
    iyzicoPaymentResults_Price: string;
    iyzicoPaymentResults_PaidPrice: string;
    iyzicoPaymentResults_PaymentId: string;
    iyzicoPaymentResults_PaymentStatus: string;
    iyzicoPaymentResults_CardType: string;
    iyzicoPaymentResults_CardFamily: string;
    iyzicoPaymentResults_CardToken: string;
    iyzicoPaymentResults_BinNumber: string;
    iyzicoPaymentResults_LastFourDigits: string;
    iyzicoPaymentResults_PaymentItems: string;
    iyzicoPaymentResults_FlowId: number | null;
    iyzicoPaymentResults_UserCreditTransactionId: number | null;
}

export interface GetSummaryRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface GetSummaryResult {
    flowSubject: string;
    serviceFee: number;
    pricePerPage: number;
    pricePerSigner: number;
    oneTimeFee: number;
    creditBalance: number;
    pageCount: number;
    signerCount: number;
    alreadyPaid: boolean;
    creditBalanceString: string;
    creditForOneFlow: number;
    minCreditPriceForOneFlow: number;
    minCreditPriceForOneFlowString: string;
    pageCountForPricing: number;
    oneTimeFeeKdv: number;
    canStartWithOutPayment: boolean;
    onlySignerIsSelf: boolean;
    firstSignerIsSelf: boolean;
    flowId: number;
}

export interface ImpersonationRequest {
    userName: string;
}

export enum IyzicoPaymentTypeEnum {
    ForOneTimeFee = 1,
    ForCreditDeposit = 2
}

export enum KepEvidenceTypeEnum {
    AcceptedByKEPHS = 10,
    RejectedByKEPHS = 11,
    AcceptedByOtherKEPHS = 20,
    RejectedByOtherKEPHS = 21,
    CouldNotBeSentToOtherKEPHS = 31,
    IsInInboxOfKEPReceiver = 40,
    CouldNotBePutInInboxOfKEPReceiver = 41,
    ReadByKEPReceiver = 60,
    ReadAndAcceptedByKepReceiver = 61,
    Downloaded = 70,
    CouldNotDownloaded = 71
}

export enum KEPProviderEnum {
    PTT = 0,
    TURKKEP = 1,
    TNB = 2
}





export enum MoneyTransferStatusEnum {
    Created = 1,
    Accepted = 2,
    Declined = 3
}

export enum MoneyTransferTypeEnum {
    ForCreditDeposit = 1,
    ForUserLicense = 2,
    ForUpgradePlan = 3
}

export interface ResendFlowEMailRequest extends BaseRequest {
    signerToken: string;
}

export interface GetOtpRequest extends BaseRequest {
    signerToken: string;
}

export interface OpenSigningPortalRequest extends BaseRequest {
    signerToken: string;
}

export interface OpenSigningPortalWithJwtRequest extends BaseRequest {
    signerToken: string;
    jwt: string;
}

export interface SigningPortalInitRequest extends BaseRequest {
    signerToken: string;
    jwt: string;
    captchaToken: string | null;
}

export interface ResendFlowEMailResult {
    postmarkError: PostmarkError;
    tokenError_TokenNotFound: boolean;
}

export interface GetOtpResult {
    otp: SigningPortalInitResultOtp;
    tokenError_TokenNotFound: boolean;
}

export interface SigningPortalInitResult {
    tokenError_TokenNotFound: boolean;
    tokenError_TokenUsedMoreThanThreeTimes: boolean;
    tokenError_TokenUsed: boolean;
    tokenError_TokenIsOld: boolean;
    otp: SigningPortalInitResultOtp;
    userChoice_GotoLogin: boolean;
    userChoice_AskUserWantNewTokenToEMail: boolean;
    userChoice_OpenDocumentList: boolean;
    userChoice_OpenDocument: boolean;
    userChoice_AskOtpOrLogin: boolean;
    userChoice_GotoLogin_Message: string;
    userChoice_GotoLoginDirectly: boolean;
    userChoice_OpenDocumentList_UserDisplayName: string;
    userChoice_OpenDocumentList_LoginSessionId: string | null;
    userChoice_OpenDocumentList_EMail: string;
    userChoice_OpenDocumentList_UserId: number;
    userChoice_OpenDocumentList_PhoneNumber: string;
    userChoice_OpenDocumentList_GsmOperator: string;
    userChoice_GotoOtp: boolean;
    userChoice_GotoOtp_Otp: string;
    userChoice_OpenDocument_LoginSessionId: string | null;
    userChoice_OpenDocument_UserDisplayName: string;
    userChoice_OpenDocument_EMail: string;
    userChoice_OpenDocument_UserId: number;
    userChoice_OpenDocument_PhoneNumber: string;
    userChoice_OpenDocument_GsmOperator: string;
}

export interface SigningPortalInitResultOtp {
    postmarkError: PostmarkError;
    usedEMailAddress: string;
    operationName: string;
}

export interface OpenSigningPortalResult {
    tokenError_IncorrectToken: boolean;
    tokenError_DeletedDueToWrongOtpCheck: boolean;
    tokenDeletedDueToWrongOtpCheck_ResentFlowEMail: boolean;
    tokenError_AlreadyUsed: boolean;
    tokenError_Expired: boolean;
    firstLogin: boolean;
    otpOperationName: string;
    usedEMailAddress: string;
    postmarkError: PostmarkError;
    isSystemUser: boolean;
}

export interface OpenSigningPortalWithJwtResult {
    tokenError_IncorrectToken: boolean;
    tokenError_DeletedDueToWrongOtpCheck: boolean;
    tokenError_AlreadyUsed: boolean;
    tokenError_Expired: boolean;
    postmarkError: PostmarkError;
    jwtOk: boolean;
    userDisplayName: string;
    isSystemUser: boolean;
    signPortalData: SignPortalData;
}

export interface SignPortalData {
    flowAccessToken: string;
    sendersEMail: string;
    flowItems: CheckOtpResultFlowItem[];
    flowItemId: number;
    files: CheckOtpResultFlowDocument[];
    subject: string;
    signerAppLinuxVersion: number;
    signerAppMacVersion: number;
    signerAppWindowsUrl: string;
    signerAppLinuxUrl: string;
    signerAppMacUrl: string;
    signerAppWindowsVersion: number;
    flowId: number;
    flowUniqueId: string;
    flowType: FlowTypeEnum;
    noteCount: number;
    attachmentCount: number;
}

export interface PostmarkError {
    no: number;
    noString: string;
    title: string;
    description: string;
}

export enum OrganizationUserRightEnum {
    ManageDepartments = 1,
    FinancialOperations = 2,
    ManageUsers = 3,
    SendWithSftp = 4,
    ManageContacts = 5,
    ManageSftpTargets = 6
}

export enum OutgoingKepMessageStatusEnum {
    Created = 1,
    Signed = 2,
    ErrorInSending = 3,
    Sent = 4
}

export enum OutgoingKepMessageLogTypeEnum {
    ErrorInSending = 1,
    Sent = 2
}





export interface PayOneTimeFeePayTrRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number | null;
    receiptAddressId: number;
    cardHolderName: string;
    cardNumber: string;
    expireMonth: string;
    expireYear: string;
    cvv: string;
}

export interface PayForLicensePayTrRequest extends BaseRequest {
    loginSessionId: string;
    newLicenseCount: number;
    receiptAddressId: number;
    cardHolderName: string;
    cardNumber: string;
    expireMonth: string;
    expireYear: string;
    cvv: string;
    organizationNewDisplayName: string;
    organizationNewLegalName: string;
}

export interface PayForUpgradePlanPayTrRequest extends BaseRequest {
    loginSessionId: string;
    receiptAddressId: number;
    cardHolderName: string;
    cardNumber: string;
    expireMonth: string;
    expireYear: string;
    cvv: string;
    organizationNewDisplayName: string;
    organizationNewLegalName: string;
    planName: string;
}

export interface PayOneTimeFeePayTrResult {
    threeDPageRelativeUrl: string;
    oid: string;
}

export interface PayForLicensePayTrResult {
    threeDPageRelativeUrl: string;
    oid: string;
    noRight: boolean;
}

export interface PayForUpgradePlanPayTrResult {
    threeDPageRelativeUrl: string;
    oid: string;
    noRight: boolean;
}

export enum PayTrPaymentTypeEnum {
    ForOneTimeFee = 1,
    ForCreditDeposit = 2,
    ForUserLicense = 3,
    ForUpgradePlan = 4
}

export interface PayWithCreditRequest extends BaseRequest {
    flowId: number | null;
    loginSessionId: string;
}

export interface PayWithCreditResult {
    newCreditBalance: number;
}

export interface ProductFeatures {
    isOnPremises: string;
    domainName: string;
    productName: string;
    companyName: string;
    companyLegalName: string;
    companyAddress: string;
    serverName: string;
    canSendKep: string;
    canUseUim: string;
    canSendSFTP: string;
    canCreateEyp1: string;
    canSendToExternalUsers: string;
    canUseAdvancedFlow: string;
    totalApiLicenseCount: string;
    totalCoreApiLicenseCount: string;
    corsOrigins: string;
}

export enum QrCodeModeEnum {
    DontUse = 1,
    Use = 2
}

export enum ReceiptTypeEnum {
    ForOneTimeFeePaidWithIyzico = 1,
    ForCreditDepositPaidWithIyzico = 2,
    ForCreditDepositPaidWithMoneyTransfer = 3,
    ForOneTimeFeePaidWithPayTr = 4,
    ForCreditDepositPaidWithPayTr = 5,
    ForUserLicensePaidWithPayTr = 6,
    ForUserLicensePaidWithMoneyTransfer = 7,
    ForUpgradePlanPaidWithPayTr = 8
}

export interface RefreshTokenRequest {
    refreshToken: string;
}

export interface RenameFlowRequest extends BaseRequest {
    loginSessionId: string;
    newSubject: string;
    flowId: number;
}

export interface AddEYazismaTargetRequest extends BaseRequest {
    loginSessionId: string;
    contactId: number | null;
    detsisDepartmentId: number | null;
    flowId: number;
}

export interface DeleteEYazismaTargetRequest extends BaseRequest {
    loginSessionId: string;
    contactId: number;
    flowId: number;
    id: number;
}

export interface RenameFlowResult {
    newSubject: string;
    lastUpdatedDateString: string;
}

export interface AddEYazismaTargetResult {
    flowEYazismaTargetId: number | null;
    contactId: number;
    contactFullName: string;
    contactEmail: string;
}

export interface DeleteEYazismaTargetResult {
    flowEYazismaTargetId: number | null;
}

export interface ResetMyPasswordRequest extends BaseRequest {
    eMail: string;
}

export interface ResetMyPasswordResult {
    resetCodeSentSuccessfully: number;
}

export interface SaveFlowRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    flowItems: SaveFlowRequestFlowItem[];
    dontCheckReqirements: boolean;
    flowType: FlowTypeEnum;
}

export interface SaveTcForFlowItemRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    flowItemId: number;
    citizenshipNo: string;
    contactId: number | null;
}

export interface SaveFlowRequestFlowItem {
    fullname: string;
    eMail: string;
    citizenshipNo: string;
    userId: number | null;
    orderNo: number;
    color: string;
    serverId: number;
    flowItemType: FlowItemTypeEnum;
    groupAuthorityType: FlowItemGroupAuthorityTypeEnum;
    groupNo: number;
}

export interface GetSystemJobActionSendKepRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    actionSendKepId: number;
}

export interface GetSystemJobActionSendEMailRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    actionSendEMailId: number;
}

export interface GetSystemJobActionSendSftpRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    actionSendSftpId: number;
}

export interface DeleteSystemJobActionSendKepRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    actionSendKepId: number;
}

export interface DeleteSystemJobActionSendEMailRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    actionSendEMailId: number;
}

export interface DeleteSystemJobActionSendSftpRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    actionSendSftpId: number;
}

export interface GetSystemJobsForFlowRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    flowOperationCondition: FlowOperationConditionEnum;
}

export interface GetSystemJobsForFlowResult {
    actionSendKeps: GetSystemJobsForFlowResultActionSendKep[];
    actionSendEMails: GetSystemJobsForFlowResultActionSendEMail[];
    actionSendSftps: GetSystemJobsForFlowResultActionSendSftp[];
}

export interface GetSystemJobActionSendKepResult {
    id: number;
    senderKepAccountId: number;
    actionSendKepTargets: GetSystemJobActionSendKepResultTarget[];
    kepBody: string;
    subject: string;
}

export interface GetSystemJobActionSendEMailResult {
    id: number;
    actionSendEMailTargets: GetSystemJobActionSendEMailResultTarget[];
    mailBody: string;
    subject: string;
}

export interface GetSystemJobActionSendSftpResult {
    id: number;
    sftpTargetId: number;
}

export interface DeleteSystemJobActionSendKepResult {
    id: number;
}

export interface DeleteSystemJobActionSendEMailResult {
    id: number;
}

export interface DeleteSystemJobActionSendSftpResult {
    id: number;
}

export interface GetSystemJobsForFlowResultActionSendKep {
    id: number;
    senderKepAccountId: number;
    actionSendKepTargets: GetSystemJobsForFlowResultActionSendKepTarget[];
    senderKepAccountDisplayName: string;
    senderKepAccountKepMailAddress: string;
}

export interface GetSystemJobsForFlowResultActionSendEMail {
    id: number;
    actionSendEMailTargets: GetSystemJobsForFlowResultActionSendEMailTarget[];
    subject: string;
}

export interface GetSystemJobsForFlowResultActionSendSftp {
    id: number;
    sftpTargetId: number;
    sftpTarget_DisplayName: string;
    sftpTarget_ServerAddress: string;
}

export interface GetSystemJobActionSendKepResultTarget {
    id: number;
    contactId: number | null;
    kepAddress: string;
    contactFullName: string;
}

export interface GetSystemJobActionSendEMailResultTarget {
    id: number;
    contactId: number | null;
    eMail: string;
    contactFullName: string;
}

export interface GetSystemJobsForFlowResultActionSendKepTarget {
    id: number;
    contactId: number | null;
    kepAddress: string;
    contactFullName: string;
}

export interface GetSystemJobsForFlowResultActionSendEMailTarget {
    id: number;
    contactId: number | null;
    eMail: string;
    contactFullName: string;
}

export interface SaveKepMailRequest extends BaseRequest {
    loginSessionId: string;
    kepAccountId: number;
    subject: string;
    message: string;
    flowId: number;
    addSignedDocument: boolean;
    targets: SaveKepMailRequestTarget[];
    detsisNo: string;
}

export interface SaveKepMailRequestTarget {
    id: number | null;
    contactId: number | null;
    kepAddress: string;
}

export interface AddKepMailForSystemJobRequest extends BaseRequest {
    loginSessionId: string;
    kepAccountId: number;
    subject: string;
    message: string;
    flowId: number;
    addSignedDocument: boolean;
    targets: AddKepMailForSystemJobRequestTarget[];
    detsisNo: string;
}

export interface AddEMailForSystemJobRequest extends BaseRequest {
    loginSessionId: string;
    subject: string;
    message: string;
    flowId: number;
    addSignedDocument: boolean;
    targets: AddEMailForSystemJobRequestTarget[];
}

export interface SaveKepMailForSystemJobRequest extends BaseRequest {
    loginSessionId: string;
    kepAccountId: number;
    subject: string;
    message: string;
    flowId: number;
    addSignedDocument: boolean;
    targets: SaveKepMailForSystemJobRequestTarget[];
    actionSendKepId: number;
    detsisNo: string;
}

export interface SaveEMailForSystemJobRequest extends BaseRequest {
    loginSessionId: string;
    subject: string;
    message: string;
    flowId: number;
    addSignedDocument: boolean;
    targets: SaveEMailForSystemJobRequestTarget[];
    actionSendEMailId: number;
}

export interface AddSftpForSystemJobRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    targetSftpAccountId: number;
}

export interface SaveSftpForSystemJobRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    actionSendFtpId: number;
    targetSftpAccountId: number;
}

export interface SaveKepMailForSystemJobRequestTarget {
    id: number | null;
    contactId: number | null;
    kepAddress: string;
}

export interface SaveEMailForSystemJobRequestTarget {
    id: number | null;
    contactId: number | null;
    eMail: string;
}

export interface AddKepMailForSystemJobRequestTarget {
    id: number | null;
    contactId: number | null;
    kepAddress: string;
}

export interface AddEMailForSystemJobRequestTarget {
    id: number | null;
    contactId: number | null;
    eMail: string;
}

export interface SendKepViaPttRequest extends BaseRequest {
    loginSessionId: string;
    outgoingKepMessageId: number;
}

export interface SendKepRequest extends BaseRequest {
    loginSessionId: string;
    outgoingKepMessageId: number;
}

export interface SendKepViaTurkkepRequest extends BaseRequest {
    loginSessionId: string;
    outgoingKepMessageId: number;
}

export interface SendKepViaTnbKepRequest extends BaseRequest {
    loginSessionId: string;
    outgoingKepMessageId: number;
}

export interface KepReportRequest extends BaseRequest {
    loginSessionId: string;
    outgoingKepMessageStatus: OutgoingKepMessageStatusEnum | null;
    dateStart: string | null;
    dateEnd: string | null;
    kepAddressFilter: string;
    getItemsNeedsToBeSentByMe: boolean;
}

export interface SftpReportRequest extends BaseRequest {
    loginSessionId: string;
    sftpLogType: SftpLogTypeEnum | null;
    dateStart: string | null;
    dateEnd: string | null;
    sftpTargetId: number | null;
}

export interface GetKepLogRequest extends BaseRequest {
    loginSessionId: string;
    outgoingKepMessageId: number;
}

export interface GetEvidencesFromKepHsLogRequest extends BaseRequest {
    loginSessionId: string;
    outgoingKepMessageId: number;
}

export interface GetKepEvidenceLogRequest extends BaseRequest {
    loginSessionId: string;
    outgoingKepMessageId: number;
}

export interface SaveKepMailResult {
    noRight: boolean;
    outgoingKepMessageId: number;
}

export interface AddSftpForSystemJobResult {
    id: number;
    sftpTargetId: number;
    sftpTarget_DisplayName: string;
    sftpTarget_ServerAddress: string;
}

export interface SaveSftpForSystemJobResult {
    id: number;
    sftpTargetId: number;
    sftpTarget_DisplayName: string;
    sftpTarget_ServerAddress: string;
}

export interface SaveKepMailForSystemJobResult {
    id: number;
    senderKepAccountId: number;
    actionSendKepTargets: SaveKepMailForSystemJobResultKepTarget[];
    senderKepAccountDisplayName: string;
    senderKepAccountKepMailAddress: string;
}

export interface SaveEMailForSystemJobResult {
    id: number;
    actionSendEMailTargets: SaveEMailForSystemJobResultTarget[];
    subject: string;
}

export interface SaveKepMailForSystemJobResultKepTarget {
    id: number;
    contactId: number | null;
    kepAddress: string;
    contactFullName: string;
}

export interface SaveEMailForSystemJobResultTarget {
    id: number;
    contactId: number | null;
    eMail: string;
    contactFullName: string;
}

export interface AddKepMailForSystemJobResult {
    id: number;
    senderKepAccountId: number;
    actionSendKepTargets: AddKepMailForSystemJobResultKepTarget[];
    senderKepAccountDisplayName: string;
    senderKepAccountKepMailAddress: string;
}

export interface AddEMailForSystemJobResult {
    id: number;
    addEMailForSystemJobResultTargets: AddEMailForSystemJobResultTarget[];
    subject: string;
}

export interface AddKepMailForSystemJobResultKepTarget {
    id: number;
    contactId: number | null;
    kepAddress: string;
    contactFullName: string;
}

export interface AddEMailForSystemJobResultTarget {
    id: number;
    contactId: number | null;
    eMail: string;
    contactFullName: string;
}

export interface SendKepViaPttResult {
    noRight: boolean;
    outgoingKepMessageId: number;
    outgoingKepMessageStatus: OutgoingKepMessageStatusEnum;
    outgoingKepMessageStatusString: string;
}

export interface SendKepViaTurkkepResult {
    noRight: boolean;
    outgoingKepMessageId: number;
    outgoingKepMessageStatus: OutgoingKepMessageStatusEnum;
    outgoingKepMessageStatusString: string;
}

export interface SendKepViaTnbKepResult {
    noRight: boolean;
    outgoingKepMessageId: number;
    validSentAddresses: string;
    validUnsentAddresses: string;
    invalidAddresses: string;
    outgoingKepMessageStatus: OutgoingKepMessageStatusEnum;
    outgoingKepMessageStatusString: string;
}

export interface GetKepEvidenceLogResult {
    noRight: boolean;
    items: GetKepEvidenceLogResultItem[];
}

export interface GetKepEvidenceLogResultItem {
    createdDate: string;
    description: string;
    evidenceSourceId: string;
    kepEvidenceType: KepEvidenceTypeEnum;
    sourceStatus: number;
    evidenceSourceDate: string;
    kepEvidenceTypeString: string;
}

export interface GetKepLogResult {
    noRight: boolean;
    items: GetKepLogResultItem[];
}

export interface GetEvidencesFromKepHsLogResultOperationItem {
    message: string;
    exceptionMessage: string;
    logType: string;
    createdDate: string;
}

export interface GetEvidencesFromKepHsLogResult {
    noRight: boolean;
    getEvidenceFromKepHsLogs: GetEvidencesFromKepHsLogResultOperationItem[];
    success: boolean;
}

export interface GetKepLogResultItem {
    createdDate: string;
    outgoingKepMessageLogType: OutgoingKepMessageLogTypeEnum;
    exceptionMessage: string;
    returnMessage: string;
    creatorUserId: number;
    validUnsentAddresses: string;
    invalidAddresses: string;
    validSentAddresses: string;
    outgoingKepMessageLogTypeString: string;
    creatorUser_DisplayName: string;
    creatorUser_EMail: string;
}

export interface KepReportResult {
    noRight: boolean;
    items: KepReportResultItem[];
}

export interface KepReportResultItem {
    createdDate: string;
    outgoingKepMessageStatus: OutgoingKepMessageStatusEnum;
    flow_Subject: string;
    flow_UniqueId: string;
    id: number;
    kepId: string;
    senderKepAccountId: number;
    senderKepAccount_DisplayName: string;
    tos: KepReportResultToItem[];
    outgoingKepMessageStatusString: string;
    senderKepAccount_KEPProvider: KEPProviderEnum;
    senderKepAccount_KEPProviderString: string;
    flow_Id: number;
    senderKepAccount_OwnerUser_DisplayName: string;
    senderKepAccount_OwnerUser_EMail: string;
    subject: string;
}

export interface SftpReportResult {
    noRight: boolean;
    items: SftpReportResultItem[];
}

export interface SftpReportResultItem {
    createdDate: string;
    sftpLogType: SftpLogTypeEnum;
    flow_Subject: string;
    flow_UniqueId: string;
    id: number;
    flow_Id: number;
    detailedLog: string;
    flowHistoryId: number | null;
    serverAddress: string;
    serverPort: number;
    sftpTargetId: number;
    targetFileName: string;
    targetFolderName: string;
    userNote: string;
    sftpTargetDisplayName: string;
    sftpLogTypeString: string;
}

export interface KepReportResultToItem {
    fullName: string;
}

export interface SendKepResult {
    noRight: boolean;
    outgoingKepMessageId: number;
    validSentAddresses: string;
    validUnsentAddresses: string;
    invalidAddresses: string;
    outgoingKepMessageStatus: OutgoingKepMessageStatusEnum;
    outgoingKepMessageStatusString: string;
}

export interface SaveReceiptAddressRequest extends BaseRequest {
    loginSessionId: string;
    receiptAddressId: number;
    address: string;
    cityName: string;
    companyName: string;
    companyTaxNo: string;
    companyTaxOffice: string;
    country: string;
    personFirstName: string;
    personLastName: string;
    citizenshipNo: string;
    displayName: string;
    isCompany: boolean;
    zipCode: string;
}

export interface SaveReceiptAddressResult {
    savedReceiptAddressesId: number;
    noRight: boolean;
}

export interface DeleteSignatureRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    signatureId: string;
}

export interface DeleteFreeTextBoxRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    freeTextBoxId: string;
}

export interface SetFieldsRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    signatures: SetFieldsRequestSignature[];
    qrCodeLeft: string;
    qrCodeRight: string;
    qrCodeTop: string;
    qrCodeBottom: string;
    qrCodeTransformOrigin: string;
}

export interface SaveTemplateRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    freeTextBoxes: SaveTemplateRequestFreeTextBox[];
    signatures: SaveTemplateRequestSignature[];
    qrCodeMode: QrCodeModeEnum;
    confirmationMode: ConfirmationModeEnum;
    qrCodeLeft: string;
    qrCodeRight: string;
    qrCodeTop: string;
    qrCodeBottom: string;
    qrCodeTransformOrigin: string;
    lawStringLeft: string;
    lawStringRight: string;
    lawStringTop: string;
    lawStringBottom: string;
    lawStringTransformOrigin: string;
}

export interface SetFieldsRequestSignature {
    x: number;
    y: number;
    pageId: number;
    flowItemId: number;
}

export interface SaveTemplateRequestSignature {
    id: string;
    width: number;
    height: number;
    x: number;
    y: number;
    pageId: number;
    flowItem: SaveTemplateRequestFlowItem;
}

export interface SaveTemplateRequestFreeTextBox {
    id: string;
    width: number;
    height: number;
    x: number;
    y: number;
    pageId: number;
    content: string;
}

export interface SaveTemplateRequestFlowItem {
    id: number;
    eMail: string;
    fullname: string;
    orderNo: number;
    color: string;
}

export interface SaveTemplateResult {
    myProperty: number;
}

export interface DeleteSignatureResult {
    serverFlowDeleted: boolean;
}

export interface DeleteFreeTextBoxResult {
    serverFreeTextBoxDeleted: boolean;
}

export interface SendFlowEMailRequest extends BaseRequest {
    loginSessionId: string;
    flowItemId: number;
    getSignUrl: boolean;
}

export interface SaveFlowTaskRequest extends BaseRequest {
    loginSessionId: string;
    sftpTargetId: number;
    flowId: number;
}

export interface SendFlowEMailResult {
    isOk: boolean;
    signLink: string;
}

export interface SaveFlowTaskResult {
    isOk: boolean;
}

export interface SendReceiptPdfAsEmailRequest extends BaseRequest {
    loginSessionId: string;
    receiptId: number;
}

export interface SendReceiptPdfAsEmailResult {
    eMailSent: boolean;
    eMailAddress: string;
    noRight: boolean;
}

export interface ServiceStatusResult {
    firebaseNickname: string;
    databaseApplicationName: string;
    apiDllVersion: string;
}

export enum SftpLogTypeEnum {
    Success = 1,
    Error = 2
}

export interface SignInRequest extends BaseRequest {
    eMail: string;
    password: string;
    azureToken: string;
    twoFactorPassword: string;
    twoFactorOperationName: string;
    loginShortCode: string;
    googleToken: string;
}

export interface SignInUpResult {
    loginSessionId: string;
    userId: number;
    idToken: string;
    eMail: string;
    displayName: string;
    organizationDisplayName: string;
    organizationId: number;
    eMailVerified: boolean | null;
    plan: SignInUpResultPlan;
    subscription: SignInUpResultSubscription;
    isSignUp: boolean;
    signingPortalUrl: string;
    displayLanguage: string;
    signerAppWindowsVersion: number;
    signerAppLinuxVersion: number;
    signerAppMacVersion: number;
    signerAppWindowsUrl: string;
    signerAppLinuxUrl: string;
    signerAppMacUrl: string;
    twoFactorOperationName: string;
    phoneNumber: string;
    gsmOperator: string;
    jwt: string;
    twoFactorPhone: string;
}

export interface SignInUpResultSubscription {
    canSendKep: boolean;
    canSendSftp: boolean;
    canCreateCades: boolean;
    canCreatePades: boolean;
    canCreateEyp1: boolean;
    canBuyCreditWithOnlinePayment: boolean;
    canSendToExternalUsers: boolean;
    canUseAdvancedFlow: boolean;
    isEnterprise: boolean;
    displayName: string;
    name: string;
    displayNameForWeb: string;
    totalApiLicenseCount: number;
    totalCoreApiLicenseCount: number;
    canUseUim: boolean;
}

export interface SignInUpResultPlan {
    name: string;
    displayName: string;
    isFree: boolean;
    isEnterprise: boolean;
    displayNameForWeb: string;
}

export interface SignStepOneRequest extends BaseRequest {
    cerBytes: string;
    cerSerialNumber: string;
    flowAccessTokenString: string;
    coordinates: SignStepOneRequestCoordinates;
    uimPin: string | null;
    uimSettingUniqueId: string | null;
    uimUserId: number | null;
}

export interface SignStepOneCoreInternalForCadesRequest extends BaseRequest {
    cerBytes: string;
}

export interface SignStepOneCadesCoreRequest extends BaseRequest {
    cerBytes: string;
    fileData: string;
    signatureIndex: number;
    coordinates: SignStepOneRequestCoordinates;
    operationId: string;
}

export interface SignStepOneCadesMobileCoreRequest extends BaseRequest {
    cerBytes: string;
    fileData: string;
    signatureIndex: number;
    coordinates: SignStepOneRequestCoordinates;
    operationId: string;
    phoneNumber: string;
    operator: string;
    userPrompt: string;
    citizenshipNo: string | null;
}

export interface VerificationInfo {
    text: string;
    width: number;
    height: number;
    left: number | null;
    right: number | null;
    top: number | null;
    bottom: number | null;
    transformOrigin: string;
}

export interface SignatureWidgetInfo {
    width: number;
    height: number;
    left: number | null;
    right: number | null;
    top: number | null;
    bottom: number | null;
    transformOrigin: string;
    imageBytes: string;
    pagesToPlaceOn: number[];
    lines: LineInfo[];
}

export interface QrCodeInfo {
    text: string;
    width: number;
    left: number | null;
    right: number | null;
    top: number | null;
    bottom: number | null;
    transformOrigin: string;
}

export interface SignStepOneCoreInternalForPadesMobileRequest extends BaseRequest {
    inputFilePath: string;
    outputFilePath: string;
    phoneNumber: string;
    userPrompt: string;
    operationId: string;
    operatorName: string;
    signatureIndex: number;
    citizenshipNo: string | null;
}

export interface SignStepOneCoreInternalForCadesMobileRequest extends BaseRequest {
    inputFilePath: string;
    outputFilePath: string;
    phoneNumber: string;
    userPrompt: string;
    operationId: string;
    operatorName: string;
    signatureIndex: number;
    citizenshipNo: string | null;
}

export interface SignStepOnePadesCoreRequest extends BaseRequest {
    cerBytes: string;
    fileData: string;
    signatureIndex: number;
    coordinates: SignStepOneRequestCoordinates;
    operationId: string;
    verificationInfo: VerificationInfo;
    qrCodeInfo: QrCodeInfo;
    signatureWidgetInfo: SignatureWidgetInfo;
}

export interface SignStepOnePadesMobileCoreRequest extends BaseRequest {
    fileData: string;
    signatureIndex: number;
    coordinates: SignStepOneRequestCoordinates;
    operationId: string;
    verificationInfo: VerificationInfo;
    qrCodeInfo: QrCodeInfo;
    phoneNumber: string;
    operator: string;
    userPrompt: string;
    citizenshipNo: string | null;
}

export interface SignStepOneRequestCoordinates {
    accuracy: number | null;
    altitude: number | null;
    altitudeAccuracy: number | null;
    heading: number | null;
    latitude: number;
    longitude: number;
    speed: number | null;
}

export interface LineInfo {
    text: string;
    leftMargin: number;
    topMargin: number;
    bottomMargin: number;
    rightMargin: number;
    fontName: string;
    fontSize: number;
    fontStyle: string;
    colorHtml: string;
}

export interface SignStepOneRequestPinCoordinates {
    coordinates: SignStepOneRequestCoordinates;
    pin: string;
}

export interface ApproveRequest extends BaseRequest {
    flowAccessTokenString: string;
}

export interface DeclineRequest extends BaseRequest {
    flowAccessTokenString: string;
    userNote: string;
}

export interface SignStepOneCadesToSMimeRequest extends BaseRequest {
    cerBytes: string;
    cerSerialNumber: string;
    loginSessionId: string;
    flowId: number;
    outgoingKepMessageId: number;
}

export interface DeclineResult {
    isOk: boolean;
}

export interface ApproveResult {
    isOk: boolean;
}

export interface SignStepOneResult {
    state: string;
    keyID: string;
    keySecret: string;
    uimOk: boolean | null;
}

export interface SignStepOneCoreInternalForCadesResult {
    state: string;
    keyID: string;
    keySecret: string;
}

export interface SignStepOneCadesCoreResult {
    state: string;
    keyID: string;
    keySecret: string;
}

export interface ConvertToPdfCoreResult {
    fileData: string;
}

export interface AddLayersCoreResult {
    fileData: string;
}

export interface AddLayersToExistingFileCoreResult {
    isSuccess: boolean;
}

export interface SignStepOneCoreInternalForPadesResult {
    state: string;
    keyID: string;
    keySecret: string;
}

export interface SignStepOneCoreInternalForPadesMobileResult {
    isSuccess: boolean;
}

export interface SignStepOneCoreInternalForCadesMobileResult {
    isSuccess: boolean;
}

export interface SignStepOnePadesCoreResult {
    state: string;
    keyID: string;
    keySecret: string;
}

export interface SignStepOneCadesToSMimeResult {
    state: string;
    keyID: string;
    keySecret: string;
}

export interface SignStepOneUploadFileResult {
    isSuccess: boolean;
    operationId: string;
}

export interface VerifySignaturesCoreResult {
    captchaError: boolean;
    allSignaturesValid: boolean;
    signatures: VerifyDocumentResultSignature[];
    timestamps: VerifyDocumentResultTimestamp[];
    fileName: string;
    signatureType: string;
}

export interface SignStepThreeRequest extends BaseRequest {
    signedData: string;
    keyId: string;
    keySecret: string;
    flowAccessTokenString: string;
    isUim: boolean | null;
}

export interface ConvertToPdfCoreRequest extends BaseRequest {
    operationId: string;
    fileData: string;
    fileName: string;
}

export interface AddLayersCoreRequest extends BaseRequest {
    operationId: string;
    fileData: string;
    fileName: string;
    verificationInfo: VerificationInfo;
    qrCodeInfo: QrCodeInfo;
}

export interface AddLayersToExistingFileCoreRequest extends BaseRequest {
    operationId: string;
    verificationInfo: VerificationInfo;
    qrCodeInfo: QrCodeInfo;
}

export interface DownloadSignedFileCoreRequest extends BaseRequest {
    operationId: string;
}

export interface DownloadSignedFileCoreResult {
    operationId: string;
    fileData: string;
    fileName: string;
}

export interface SignStepThreeCadesCoreRequest extends BaseRequest {
    signedData: string;
    keyId: string;
    keySecret: string;
    operationId: string;
}

export interface SignStepThreePadesCoreRequest extends BaseRequest {
    dontUpgradeToLtv: boolean;
    signedData: string;
    keyId: string;
    keySecret: string;
    operationId: string;
}

export interface UpgradePadesCoreRequest extends BaseRequest {
    operationId: string;
}

export interface UpgradeCadesCoreRequest extends BaseRequest {
    operationId: string;
}

export interface SignStepThreeForEYazismaRequest extends BaseRequest {
    signedData: string;
    keyId: string;
    keySecret: string;
    flowAccessTokenString: string;
    isUim: boolean | null;
}

export interface SignStepThreeCadesToSMimeRequest extends BaseRequest {
    loginSessionId: string;
    signedData: string;
    keyId: string;
    keySecret: string;
    flowId: number;
    outgoingKepMessageId: number;
}

export interface MobileSignOnApiRequest extends BaseRequest {
    phoneNumber: string;
    flowAccessTokenString: string;
    operationId: string;
    operator: string;
}

export interface GetFingerPrintOnApiRequest extends BaseRequest {
    operationId: string;
}

export interface GetFingerPrintCoreRequest extends BaseRequest {
    operationId: string;
}

export interface SignStepThreeCadesToSMimeResult {
    myProperty: number;
}

export interface SignStepThreeResult {
    myProperty: number;
}

export interface SignStepThreeCadesCoreResult {
    isSuccess: boolean;
}

export interface SignStepThreePadesCoreResult {
    isSuccess: boolean;
}

export interface UpgradePadesCoreResult {
    isSuccess: boolean;
}

export interface UpgradeCadesCoreResult {
    isSuccess: boolean;
}

export interface MobileSignOnApiResult {
    myProperty: number;
}

export interface GetFingerPrintOnApiResult {
    fingerPrint: string;
}

export interface GetFingerPrintCoreResult {
    fingerPrint: string;
}

export interface GetSignerAppVersionsResult {
    signerAppWindowsVersion: number;
    signerAppLinuxVersion: number;
    signerAppMacVersion: number;
    signerAppWindowsUrl: string;
    signerAppLinuxUrl: string;
    signerAppMacUrl: string;
}

export interface SignUpRequest extends BaseRequest {
    eMail: string;
    password1: string;
    password2: string;
    fullname: string;
    azureToken: string;
    googleToken: string;
}

export interface SignUpCoreRequest extends SignUpRequest {
    operationId: string;
}



export interface StartFlowRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
    flowSubject: string;
    note: string;
}

export interface GetFlowStatusRequest extends BaseRequest {
    loginSessionId: string;
    flowId: number;
}

export interface StartFlowResult {
    startFlowError: string;
    isOk: boolean;
    firstSignerIsSelf: boolean;
    signingPortalUrl: string;
    token: string;
    postmarkErrors: PostmarkError[];
    jwtShortCode: string;
    lastUpdatedDate: string;
    appUrl: string;
    urlForFirstSigner: string | null;
}

export interface ApiGetFlowStatusResult {
    flowId: number;
    flowItems: ApiGetFlowStatusResultFlowItem[];
    flowType: FlowTypeEnum;
    status: FlowStatusEnum;
    statusString: string;
}

export interface ApiGetFlowStatusResultFlowItem {
    id: number;
    eMail: string;
    fullname: string;
    orderNo: number;
    status: FlowItemStatusEnum;
    statusString: string;
    signedDate: string | null;
    sendDate: string | null;
    sendDateString: string;
    signedDateString: string;
    waitDurationString: string;
    groupNo: number;
    groupAuthorityType: FlowItemGroupAuthorityTypeEnum;
    flowItemType: FlowItemTypeEnum;
    groupAuthorityTypeString: string;
    flowItemTypeString: string;
    isMobileSign: boolean | null;
}

export enum TwoFactorCodeTypeEnum {
    Sms = 1
}

export interface UimSignerAppSignRequest {
    signStepOneResult_KeyID: string;
    signStepOneResult_KeySecret: string;
    signStepOneResult_State: string;
    uimSetting_CertificateCitizenshipNo: string;
    uimSetting_ComputerName: string;
    uimSetting_DeviceId: string;
    uimSetting_UniqueId: string;
    uimSetting_CertificateData: string;
    uimSetting_PkcsLibrary: string;
    uimSetting_Slot: number;
    uimPin: string | null;
}

export interface UimSignerAppSignResult {
    stepTwoResult: string;
    error: string;
}

export interface UpdateFlowItemRequest extends BaseRequest {
    loginSessionId: string;
    flowItemId: number;
    flowId: number;
    deadline: string | null;
    citizenshipNo: string | null;
}

export interface UpdateFlowItemResult {
    id: number;
}

export interface UploadResult {
    orderNo: number;
    pageCount: number | null;
    flowDocumentId: number | null;
    flowDocumentEYazismaAttachmentId: number | null;
    flowDocumentPdfAttachmentId: number | null;
    flowNoteAttachmentFileName: string;
    addedRecordId: number;
    pages: UploadResultPage[];
}

export interface ApiAddPdfAttachmentToFlowResult {
    orderNo: number;
    flowDocumentPdfAttachmentId: number;
}

export interface UploadResultPage {
    height: number;
    id: number;
    pageNo: number;
    pdfHeightInPoints: number;
    pdfWidthInPoints: number;
    width: number;
}

export interface AnonymousUploadResult {
    flowDocumentId: number;
    pageCount: number;
    orderNo: number;
}

export interface VerifyDocumentResult {
    javaPadesValidationResult: JavaPadesValidationResult;
    fileName: string | null;
    flowAccessTokenForDownload: string;
}

export interface JavaPadesValidationResult {
    signatureValidations: SignatureValidationItem[];
    summary: string;
}

export interface SignatureValidationItem {
    isExpanded: boolean;
    signerFullName: string;
    serialNumber: string;
    reason: string;
    signatureType: string;
    signatureFormat: string;
    signatureAlg: string;
    signingTime: string | null;
    signingTimeDeclared: string | null;
    policyDigestAlgorithm: string;
    policyId: string;
    policyUri: string;
    policyUserNotice: string;
    policyTurkishESigProfile: string;
    hasTimestamp: boolean;
    timestamp: TimestampValidationItem;
    validationResult: string;
    validationResultType: string;
    validationCertificateStatusInfoCheckResults: string;
    validationCertificateStatusInfoDetailedMessage: string;
    validationCertificateStatusInfoDetailedValidationReport: string;
    validationCertificateStatusInfoCheckResultsToString: string;
    validationCertificateStatusInfoValidationHistory: string;
    validationCertificateStatusInfotCertificateStatus: string;
    counterSignatureValidations: SignatureValidationItem[];
}

export interface TimestampValidationItem {
    timestampType: string;
    dateOfTimestmap: string;
}

export interface VerifyDocumentResultSignature {
    chainValidationResult: string;
    claimedSigningTime: string;
    hashAlgorithm: string;
    profile: string;
    timestamped: boolean;
    reason: string;
    level: string;
    citizenshipNo: string;
    fullName: string;
    isExpanded: boolean;
    index: number;
    issuerRDN: string;
    serialNumber: string;
    serialNumberString: string;
    subjectKeyID: string;
    subjectKeyIDString: string;
    policyDigestAlgorithm: string;
    policyId: string;
    policyTurkishESigProfile: string;
    policyUri: string;
    policyUserNotice: string;
    signatureFormat: string;
    validationResultType: string;
    validationCertificateStatusInfoCheckResults: string;
    signatureType: string;
    validationCertificateStatusInfoDetailedMessage: string;
    validationCertificateStatusInfoDetailedValidationReport: string;
    validationCertificateStatusInfoCheckResultsToString: string;
    validationCertificateStatusInfoValidationHistory: string;
    validationCertificateStatusInfotCertificateStatus: string;
}

export interface VerifyDocumentResultTimestamp {
    time: string;
    tSAName: string;
    timestampType: number;
    index: number;
    issuerRDN: string;
    serialNumber: string;
    serialNumberString: string;
    subjectKeyID: string;
    subjectKeyIDString: string;
}

export enum UserCreditTransactionTypeEnum {
    PlusGiftCredit = 1,
    PlusAddCreditWithCreditCardPayment = 2,
    PlusAddCreditWithMoneyTransfer = 3,
    MinusUseCreditForFlow = 4
}

export interface VerifyPasswordResetCodeRequest extends BaseRequest {
    oobCode: string;
}

export interface VerifyPasswordResetCodeResult {
    codeVerified: boolean;
}

export interface VerifyRequest extends BaseRequest {
    captchaToken: string;
    verificationCode: string;
}

export interface VerifyResult {
    isVerified: boolean;
    title: string;
    signers: VerifyResultSigner[];
    flowAccessToken: string;
    captchaError: boolean;
    uniqueId: string;
}

export interface VerifyResultSigner {
    fullName: string;
    signedDate: string;
}

export interface WebToAvalonSignStepTwoRequest {
    keyId: string;
    keySecret: string;
    state: string;
    pkcsLibrary: string;
    slot: number;
    pin: string;
}