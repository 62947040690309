
import { ActionTypes } from "@/store/action-types";
import {
    GetOrganizationsRequest,
    GetOrganizationsResult,
    GetOrganizationsResultItem,
    CreateSubscriptionUpgradeRequest,
    CreateSubscriptionUpgradeResult,
    SubscriptionUpgradeUpgradeTypeEnum,
    SubscriptionUpgradeStatusEnum,
    ApproveOrDenySubscriptionUpgradeRequest,
    ApproveOrDenySubscriptionUpgradeResult,
    GetOrganizationsResultUpgrade,
    GetPlansResultPlan,
    GetAllSettingsRequest,
    GetAllSettingsResult,
    GetSubscriptionUpgradeUpgradeTypeEnumsRequest,
    GetSubscriptionUpgradeUpgradeTypeEnumsResult,
    EnumItem,
    SendEMailToOrganizationsRequest,
    SendEMailToOrganizationsResult,
} from "@/types/SharedTypes";
import { StaticPhrases } from "@/types/StaticPhrases";
import StringHelper from "@/types/StringHelper";
import axios from "axios";
import { nanoid } from "nanoid";
import { defineComponent } from "vue";
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export interface GetOrganizationsResultItemExtended extends GetOrganizationsResultItem {
    isExpanded: boolean;
    isSelected: boolean;
}

export default defineComponent({
    name: "OrganizationsList",
    components: {
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
        QuillEditor,
    },
    data() {
        return {
            organizations: null as Array<GetOrganizationsResultItemExtended> | null,
            plans: null as GetPlansResultPlan[] | null,
            createSubscriptionUpgradeRequest: null as null | CreateSubscriptionUpgradeRequest,
            approveOrDenySubscriptionUpgradeRequest: null as null | ApproveOrDenySubscriptionUpgradeRequest,
            SubscriptionUpgradeUpgradeTypeEnum,
            SubscriptionUpgradeStatusEnum,
            allSettings: null as string[] | null,
            resultQuill: null as string[] | null,
            resultHtml: null as string[] | null,
            isWorking: false,
            subscriptionUpgradeUpgradeTypeEnums: null as EnumItem[] | null,
            organizationFilter: "",
            eMailFilter: "",
            planFilter: "",
            isAllSelected: false,
            mailBodyQuill: "",
            mailBodyHtml: "",
            mailSubjectQuill: "",
            mailtTargetsQuill: "",
            mailSubjectHtml: "",
            mailTargetsHtml: "",
        };
    },
    mounted() {
        this.getSubscriptionUpgradeUpgradeTypeEnums();
    },
    methods: {
        selectAll() {
            if (this.organizations) {
                if (this.isAllSelected) {
                    this.organizations.forEach((organization) => {
                        organization.isSelected = false;
                    });
                } else {
                    this.organizations.forEach((organization) => {
                        organization.isSelected = true;
                    });
                }
                this.isAllSelected = !this.isAllSelected;
            }
        },
        sendEMail(mailSubject: string, body: string, quillOrHtml: string) {
            if (quillOrHtml === "quill") {
                if (!this.organizations && !this.mailtTargetsQuill) {
                    return;
                }
                if (!this.mailtTargetsQuill && this.organizations && this.organizations.filter((organization) => organization.isSelected).map((organization) => organization.id).length === 0) {
                    return;
                }
            }
            if (quillOrHtml === "html") {
                if (!this.organizations && !this.mailTargetsHtml) {
                    return;
                }
                if (!this.mailTargetsHtml && this.organizations && this.organizations.filter((organization) => organization.isSelected).map((organization) => organization.id).length === 0) {
                    return;
                }
            }

            // if (this.organizations.filter((organization) => organization.isSelected).map((organization) => organization.id).length === 0) {
            //     return;
            // }
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                organizationIds: this.organizations?.filter((organization) => organization.isSelected).map((organization) => organization.id),
                mailAddresses: quillOrHtml === "quill" ? this.mailtTargetsQuill : this.mailTargetsHtml,
                mailSubject: mailSubject,
                mailBody: body,
            } as SendEMailToOrganizationsRequest;
            this.isWorking = true;
            axios
                .post(this.$store.getters.getApiUrl + "admin/sendEMailToOrganizations", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as SendEMailToOrganizationsResult;
                        if (quillOrHtml === "quill") {
                            this.resultQuill = result.eMails;
                        }
                        if (quillOrHtml === "html") {
                            this.resultHtml = result.eMails;
                        }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    this.isWorking = false;
                });
        },
        getOrganizations() {
            this.organizations = null;
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                organizationFilter: this.organizationFilter,
                eMailFilter: this.eMailFilter,
                planFilter: this.planFilter,
            } as GetOrganizationsRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/getOrganizations", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as GetOrganizationsResult;
                        this.organizations = result.items.map((item) => {
                            return {
                                ...item,
                                isExpanded: false,
                                isSelected: false,
                            };
                        });
                        this.plans = result.plans;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        getAllSettings() {
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
            } as GetAllSettingsRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/getAllSettings", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as GetAllSettingsResult;
                        this.allSettings = result.allSettings;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        openApproveOrDenyModal(subscriptionUpgrade: GetOrganizationsResultUpgrade, organizationId: number, subscriptionId: number, subscriptionIsActive: boolean) {
            if (subscriptionIsActive === false) return;
            if (subscriptionUpgrade.status !== SubscriptionUpgradeStatusEnum.Created) {
                // this.$store.dispatch(ActionTypes.TOASTERROR, "Sadece onay bekleyen işlemlere işlem yapılabilir.");
                return;
            }
            this.approveOrDenySubscriptionUpgradeRequest = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                planUpgradeRequestId: subscriptionUpgrade.upgradeId,
                subscriptionUpgradeId: subscriptionUpgrade.id,
                approverNote: "",
                passwordForOperation: "",
                approveOrDeny: false,
                organizationId: organizationId,
                subscriptionId: subscriptionId,
            } as ApproveOrDenySubscriptionUpgradeRequest;
        },
        openModalForCreateRequest(organizationId: number, subscriptionId: number, upgradeType: SubscriptionUpgradeUpgradeTypeEnum, subscriptionIsActive: boolean) {
            if (subscriptionIsActive === false) return;
            this.createSubscriptionUpgradeRequest = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                organizationId: organizationId,
                subscriptionId: subscriptionId,
                upgradeType: upgradeType,
                newValue: "",
            } as CreateSubscriptionUpgradeRequest;
        },
        ApproveOrDenySubscriptionUpgrade(approveOrDeny: boolean) {
            if (this.approveOrDenySubscriptionUpgradeRequest === null) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Hata.");
                return;
            }
            if (!this.approveOrDenySubscriptionUpgradeRequest.approverNote) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Açıklama giriniz.");
                return;
            }
            if (!this.approveOrDenySubscriptionUpgradeRequest.passwordForOperation) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Şifre giriniz.");
                return;
            }
            this.approveOrDenySubscriptionUpgradeRequest.approveOrDeny = approveOrDeny;
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            this.isWorking = true;
            axios
                .post(this.$store.getters.getApiUrl + "admin/approveOrDenySubscriptionUpgrade", this.approveOrDenySubscriptionUpgradeRequest, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as ApproveOrDenySubscriptionUpgradeResult;
                        this.$store.dispatch(ActionTypes.TOASTSUCCESS, "İşlemle ile ilgili e-posta gönderildi: " + (result.eMailSent ? "Evet" : "Hayır") + ". Talep onay/red işlemi tamamlandı: " + (result.savedToDb ? "Evet" : "Hayır"));
                        this.approveOrDenySubscriptionUpgradeRequest = null;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    this.isWorking = false;
                });
        },
        planUpgrade() {
            if (this.createSubscriptionUpgradeRequest === null) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Hata.");
                return;
            }
            if (!this.createSubscriptionUpgradeRequest.creatorNote) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Açıklama giriniz.");
                return;
            }
            if (!this.createSubscriptionUpgradeRequest.newValue) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Yeni değeri giriniz.");
                return;
            }
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };

            this.isWorking = true;
            axios
                .post(this.$store.getters.getApiUrl + "admin/createSubscriptionUpgrade", this.createSubscriptionUpgradeRequest, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as CreateSubscriptionUpgradeResult;
                        this.$store.dispatch(ActionTypes.TOASTSUCCESS, "Talep ile ilgili e-posta gönderildi: " + (result.eMailSent ? "Evet" : "Hayır") + ". Talep kayıt edildi: " + (result.savedToDb ? "Evet" : "Hayır"));
                        this.createSubscriptionUpgradeRequest = null;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    this.isWorking = false;
                });
        },
        formatDateLong(inputDate: string | null): string {
            if (inputDate) return StringHelper.formatDateLong(inputDate);
            return "";
        },
        getPlanName(name: string): string {
            if (this.plans && this.plans.length > 0) {
                const plan = this.plans.find((p) => p.name === name);
                if (plan) {
                    return plan.displayNameForWeb;
                }
            }
            return "";
        },
        getSubscriptionUpgradeUpgradeType(enumValue: SubscriptionUpgradeUpgradeTypeEnum): string {
            if (this.subscriptionUpgradeUpgradeTypeEnums && this.subscriptionUpgradeUpgradeTypeEnums.length > 0) {
                const plan = this.subscriptionUpgradeUpgradeTypeEnums.find((p) => p.key === enumValue);
                if (plan) {
                    return plan.description;
                }
            }
            return "";
        },
        getSubscriptionUpgradeUpgradeTypeEnums() {
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                displayLanguage: this.$i18n.locale,
            } as GetSubscriptionUpgradeUpgradeTypeEnumsRequest;
            this.isWorking = true;
            axios
                .post(this.$store.getters.getApiUrl + "enums/getSubscriptionUpgradeUpgradeTypeEnums", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        //
                    } else {
                        const getSubscriptionUpgradeUpgradeTypeEnumsResult = e.data.result as GetSubscriptionUpgradeUpgradeTypeEnumsResult;
                        if (getSubscriptionUpgradeUpgradeTypeEnumsResult.enums) {
                            this.subscriptionUpgradeUpgradeTypeEnums = getSubscriptionUpgradeUpgradeTypeEnumsResult.enums;
                        }
                    }
                })
                // .catch((error: Error | AxiosError) => {
                //     //
                // })
                .finally(() => {
                    this.isWorking = false;
                });
        },
    },
});
