
import { defineComponent } from "vue";
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import ReportUserByLoginCount from "@/components/ReportUserByLoginCount.vue";
import ReportUserByFlowCount from "@/components/ReportUserByFlowCount.vue";
import ReportUserLogin from "@/components/ReportUserLogin.vue";
import ReportUserDetails from "@/components/ReportUserDetails.vue";
import ReportNewRegistars from "@/components/ReportNewRegistars.vue";

// const ReportUserByLoginCount = () => import("@/components/ReportUserByLoginCount.vue");

const reportList = [
    { id: 1, name: 'Login count', component: "ReportUserByLoginCount" },
    { id: 2, name: 'Flow count', component: "ReportUserByFlowCount" },
    { id: 3, name: 'Logins', component: "ReportUserLogin" },
    { id: 4, name: 'User details', component: "ReportUserDetails" },
    { id: 5, name: 'New users', component: "ReportNewRegistars" },

]

export default defineComponent({
    name: "ReportsView",
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        ReportUserByLoginCount,
        ReportUserByFlowCount,
        ReportUserLogin,
        ReportUserDetails,
        ReportNewRegistars
    },
    data() {
        return {
            reports: reportList,
            selectedReport: reportList[0]
        };
    },
    computed: {
        userComponent() {
            return this.selectedReport.component;
        }
    }

});

