import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PayTrPayments from "../views/PayTrPayments.vue";
import MoneyTransfers from "../views/MoneyTransfers.vue";
import Receipts from "../views/ReceiptsView.vue";
import OrganizationsList from "../views/OrganizationsList.vue";
import ReportsView from "../views/ReportsView.vue";
import Login from "../views/LoginView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/receipts",
    name: "Receipts",
    component: Receipts,
  },
  {
    path: "/moneyTransfers",
    name: "MoneyTransfers",
    component: MoneyTransfers,
  },
  {
    path: "/payTrPayments",
    name: "PayTrPayments",
    component: PayTrPayments,
  },
  {
    path: "/organizationsList",
    name: "OrganizationsList",
    component: OrganizationsList,
  },
  {
    path: "/reportsView",
    name: "ReportsView",
    component: ReportsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
