
import { defineComponent } from "vue";
import axios from "axios";
import { nanoid } from "nanoid";
import { StaticPhrases } from "@/types/StaticPhrases";
import { SignInRequest, SignInUpResult } from "@/types/SharedTypes";
import { ActionTypes } from "@/store/action-types";
import { MutationTypes } from "@/store/mutation-types";

export class Cell {
    color!: string;
    x!: number;
    y!: number;
}
export default defineComponent({
    name: "LoginView",
    data() {
        return {
            password: "",
            email: "",
        };
    },

    methods: {
        login() {
            const req = {
                eMail: this.email,
                password: this.password,
                requestId: nanoid(),
            } as SignInRequest;
            axios
                .post(this.$store.getters.getApiUrl + "account/signIn", req)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const signInResult = e.data.result as SignInUpResult;
                        this.$store.commit(MutationTypes.SET_IDTOKEN, signInResult.idToken);
                        this.$store.commit(MutationTypes.SET_LOGINSESSIONID, signInResult.loginSessionId);
                        this.$router.push("/receipts");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.response.data);
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
    },
});
