
import { defineComponent } from "vue";
import axios from "axios";
import { nanoid } from "nanoid";
import { StaticPhrases } from "@/types/StaticPhrases";
import { GetPayTrPaymentsRequest, GetPayTrPaymentsResult, GetPayTrPaymentsResultItem } from "@/types/SharedTypes";
import { ActionTypes } from "@/store/action-types";
import StringHelper from "@/types/StringHelper";

export default defineComponent({
    name: "PayTrPayments",
    data() {
        return {
            waiting: false,
            getPayTrPaymentsResult: {} as GetPayTrPaymentsResult,
            selectedItem: null as GetPayTrPaymentsResultItem | null,
        };
    },
    mounted() {
        this.getPayTrPayments();
    },
    methods: {
        openDetails(item: GetPayTrPaymentsResultItem) {
            if (this.selectedItem == item) {
                this.selectedItem = null;
            } else {
                this.selectedItem = item;
            }
        },
        approve() {
            if (this.selectedItem === undefined || this.selectedItem === null || (this.selectedItem && Object.keys(this.selectedItem).length === 0 && this.selectedItem.constructor === Object)) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Seçili ödeme kaydı yok.");
                return;
            }
            if (this.selectedItem.receiptNumber === undefined || this.selectedItem.receiptNumber === null || this.selectedItem.receiptNumber.length === 0) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Fatura no girilmeli.");
                return;
            }
            if (this.selectedItem.receiptPdfCreationDate === undefined || this.selectedItem.receiptPdfCreationDate === null || this.selectedItem.receiptPdfCreationDate.length === 0 || this.selectedItem.receiptPdfCreationDate === "2010-01-01") {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Fatura tarihi girilmeli.");
                return;
            }
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement !== undefined && fileElement !== null && fileElement.files !== undefined && fileElement.files !== null) {
                fileElement.click();
            }
        },
        handleFileUpload() {
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement !== undefined && fileElement !== null && fileElement.files !== undefined && fileElement.files !== null && fileElement.files.length > 0) {
                for (let i = 0; i < fileElement.files.length; i++) {
                    this.uploadFile(fileElement.files[i]);
                }
            }
        },
        uploadFile(file: File) {
            const formData = new FormData();
            if (this.selectedItem === undefined || this.selectedItem === null || (this.selectedItem && Object.keys(this.selectedItem).length === 0 && this.selectedItem.constructor === Object)) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Seçili ödeme kaydı yok.");
                return;
            }

            if (this.$store.getters.getLoginSessionId === undefined) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Oturum açılmalı.");
                return;
            }
            if (this.$store.getters.getIdToken === undefined) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Oturum açılmalı.");
                return;
            }

            const headers = {
                "Content-Type": "multipart/form-data",
                receiptid: this.selectedItem.receiptId,
                receiptnumber: this.selectedItem.receiptNumber,
                requestid: nanoid(),
                loginsessionid: this.$store.getters.getLoginSessionId,
                Authorization: "Bearer " + this.$store.getters.getIdToken,
            };

            formData.append("file", file);

            axios
                .post(this.$store.getters.getApiUrl + "admin/uploadReceipt", formData, {
                    headers: headers,
                    onUploadProgress: () => {
                        // file.uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    },
                })
                .then((result) => {
                    if (result.data !== undefined && result.data !== null) {
                        const apiResult = result.data as any;
                        if (apiResult !== undefined && apiResult !== null) {
                            if (apiResult.error !== undefined && apiResult.error !== null) {
                                this.$store.dispatch(ActionTypes.TOASTERROR, apiResult.error);
                            } else {
                                this.$store.dispatch(ActionTypes.TOASTSUCCESS, "Fatura yüklendi.");
                            }
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yükleme sonucu geçersiz.");
                        }
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, "Yükleme sonucu geçersiz bir değer.");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        getPayTrPayments() {
            this.waiting = true;
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
            } as GetPayTrPaymentsRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/getPayTrPayments", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        if (e.data.result !== undefined && e.data.result !== null && e.data.result) {
                            this.getPayTrPaymentsResult = e.data.result;
                            this.getPayTrPaymentsResult.items?.forEach((x) => {
                                if (x.receiptPdfCreationDate) {
                                    const date = new Date(x.receiptPdfCreationDate);
                                    x.receiptPdfCreationDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
                                }
                            });
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTWARNING, "Kayıt bulunamadı.");
                        }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        formatDecimal(amount: number, fractionDigits = 2): string {
            return StringHelper.formatDecimal(amount, fractionDigits);
        },
        formatDateLong(inputDate: string): string {
            return StringHelper.formatDateLong(inputDate);
        },
    },
});
