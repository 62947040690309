
import { ActionTypes } from "@/store/action-types";
import { FlowStatusEnum, ReportUserByFlowCountRequest, ReportUserByFlowCountResult } from "@/types/SharedTypes";
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { StaticPhrases } from "@/types/StaticPhrases";
import StringHelper from "@/types/StringHelper";
import axios from "axios";
import { nanoid } from "nanoid";
import { defineComponent } from "vue";

interface EnumItem {
    displayString: string,
    value: number
}

const enumItems: Array<EnumItem> = Object.entries(FlowStatusEnum).filter(x => typeof x[1] == 'number').map((x) => { return { displayString: x[0], value: x[1] as FlowStatusEnum }; })


export default defineComponent({
    name: "ReportUserByFlowCount",
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
    },
    data() {
        return {
            flowStatusEnumItems: enumItems,
            selectedFlowStatus: enumItems[0],
            reportUserByFlowCountResult: null as null | ReportUserByFlowCountResult
        };
    },
    mounted() {
        // this.reportUserByFlowCount();
        console.log("Object.entries(FlowStatusEnum)", Object.entries(FlowStatusEnum).filter(x => typeof x[1] == 'number').map((x) => { return { displayString: x[0], value: x[1] as FlowStatusEnum }; }));
    },
    methods: {
        reportUserByFlowCount(flowStatusEnum: FlowStatusEnum) {
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                flowStatusEnum: flowStatusEnum
            } as ReportUserByFlowCountRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/reportUserByFlowCount", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as ReportUserByFlowCountResult;
                        this.reportUserByFlowCountResult = result;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        formatDateLong(inputDate: string | null): string {
            if (inputDate) return StringHelper.formatDateLong(inputDate);
            return "";
        },
    },
    watch: {
        selectedFlowStatus: {
            handler(newValue: EnumItem) {
                this.reportUserByFlowCount(newValue.value);
            },
            immediate: true
        }
    }


});

